import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconScience: React.FC<IconProps> = (props) => {
    const { color } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path style={{ fill: color }} d="M16.12,13.43V38.82a2.63,2.63,0,1,1-5.26,0V13.43H9.36V38.82a4.13,4.13,0,1,0,8.26,0V13.43Z" />
            <path style={{ fill: color }} d="M8.55,14.67H18a.75.75,0,0,0,0-1.5H8.55a.75.75,0,0,0,0,1.5" />
            <path style={{ fill: color }} d="M35.28,9.89V9.14h-9a.55.55,0,1,1,0-1.09h9a.55.55,0,1,1,0,1.09v1.5a2,2,0,1,0,0-4.09h-9a2,2,0,1,0,0,4.09h9Z" />
            <path style={{ fill: color }} d="M34.18,9.89V22.68l.46.2A9.92,9.92,0,1,1,26.7,23l.44-.2V9.89h-1.5v12.4h.75l-.3-.69a11.43,11.43,0,1,0,9.13-.1l-.29.69h.75V9.89Z" />
            <path style={{ fill: color }} d="M38.44,32.22h-.75a6.92,6.92,0,1,1-13.83,0,7.15,7.15,0,0,1,.14-1.4h0l.11-.18a.47.47,0,0,1,.31-.09,1.84,1.84,0,0,1,.89.3h0a5.84,5.84,0,0,0,2.93.77,7.53,7.53,0,0,0,3.07-.69,7.41,7.41,0,0,0,2.12-1.39A3.91,3.91,0,0,1,36,28.19a1.09,1.09,0,0,1,.43.08l.1.06,0,0a6.83,6.83,0,0,1,1.18,3.87h1.5a8.44,8.44,0,0,0-1.43-4.71,2.14,2.14,0,0,0-1.8-.82,5.32,5.32,0,0,0-3.58,1.78,6.09,6.09,0,0,1-1.68,1.09,6.15,6.15,0,0,1-2.46.56,4.26,4.26,0,0,1-2.18-.57h0a3.26,3.26,0,0,0-1.64-.5,1.92,1.92,0,0,0-1.29.46,1.81,1.81,0,0,0-.6,1h0a8.67,8.67,0,0,0-.17,1.7,8.42,8.42,0,1,0,16.83,0Z" />
            <path style={{ fill: color }} d="M30.12,26.56h-.75a1.1,1.1,0,1,1-1.09-1.09,1.09,1.09,0,0,1,1.09,1.09h1.5a2.6,2.6,0,1,0-2.59,2.6,2.59,2.59,0,0,0,2.59-2.6Z" />
            <path style={{ fill: color }} d="M32.61,16.16h-.75a.8.8,0,0,1-1.59,0,.8.8,0,1,1,1.59,0h1.5a2.3,2.3,0,1,0-2.29,2.29,2.3,2.3,0,0,0,2.29-2.29Z" />
        </svg>
    );
}

export default IconScience
