import clsx from 'clsx';
import React from 'react'
import GatsbyDataInterface from '../../@types/GatsbyImage';
import Breakpoints from '../../@types/layout.types';
import BackgroundImage from '../BackgroundImage/BackgroundImage';

interface Props extends Breakpoints {
    className?: string;
    wrapperClassName?: string;
    display?: "flex" | "grid";
    disableGutter?: true;
    disableWrapper?: true;
    style?: any;
    backgroundImage?: GatsbyDataInterface;
    disableCenter?: true;
    animate?: boolean; 
}

const Container: React.FC<Props> = (props) => {
    const {
        children,
        className,
        maxWidth,
        disableGutter,
        display,
        wrapperClassName,
        disableWrapper,
        backgroundImage,
        animate,
        disableCenter } = props;

    const classes = clsx(
        "container",
        className && className,
        maxWidth && `container--${maxWidth}`,
        !disableGutter && "container--gutter",
    );

    const wrapperClasses = clsx(
        "container__wrapper",
        wrapperClassName,
        display && `display--${display}`
    );
    
    const style = {};

    if (!disableCenter) {
        style["marginLeft"] = "auto";
        style["marginRight"] = "auto";
    }

    return (
        <div className={classes}>
            {backgroundImage && <BackgroundImage {...backgroundImage} animate={animate}/>}
            {disableWrapper ? children : (
                <div className={wrapperClasses} style={{...style}}>
                    {children}
                </div>
            )}
        </div>
    )
}

export default Container;
