import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconInjection: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M39.57.38A1.84,1.84,0,0,0,37,.38L35.23,2.16a1.79,1.79,0,0,0,0,2.49l-3.8,3.81-1.59-1.6a2.41,2.41,0,0,0-3.39,0l-.95.95a2.39,2.39,0,0,0-.16,3.2L8.8,27.49a3.51,3.51,0,0,0-.65,4l-2,2a1.88,1.88,0,0,0,0,2.66l.13.13L.17,42.41a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l6.07-6.07.13.13a1.88,1.88,0,0,0,2.66,0l2-2a3.63,3.63,0,0,0,1.54.37,3.5,3.5,0,0,0,2.48-1l1.34-1.34h0L25.7,26h0l2.64-2.65h0l2.59-2.59h0L33,18.71a2.39,2.39,0,0,0,3.2-.16l1-.95a2.41,2.41,0,0,0,0-3.39l-1.6-1.59,3.81-3.8a1.75,1.75,0,0,0,1.21.48,1.8,1.8,0,0,0,1.28-.53L43.62,7a1.81,1.81,0,0,0,0-2.56Zm-30.45,36L7.61,34.88l1.82-1.82,1.51,1.51ZM13,33.78,10.22,31a1.48,1.48,0,0,1,0-2.11L26.69,12.44l4.87,4.87-1.33,1.34-1.06-1.06a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l1.06,1.06-1.18,1.18-2.38-2.38a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l2.38,2.38L25,23.89l-1.06-1.07a1,1,0,0,0-1.42,1.42l1.07,1.06-1.2,1.19L20,24.11a1,1,0,0,0-1.41,1.41L21,27.9l-1.23,1.23-1.06-1.06a1,1,0,0,0-1.41,1.41l1.06,1.07-1.19,1.19-2.38-2.39a1,1,0,0,0-1.42,1.42l2.38,2.38-.63.63A1.48,1.48,0,0,1,13,33.78ZM35.72,16.19l-.95.95a.4.4,0,0,1-.56,0L26.86,9.79a.4.4,0,0,1,0-.56l.95-1a.39.39,0,0,1,.56,0l2.3,2.3h0l5,5.05A.4.4,0,0,1,35.72,16.19ZM32.79,9.87l3.8-3.8,1.34,1.34-3.8,3.8Zm7.77-2.65-.51-.52h0L36.78,3.44l1.51-1.51,3.78,3.78Z"/>
    </svg>
    );
}

export default IconInjection
