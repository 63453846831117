import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconNaturalOrigin: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M13.38,43.64A9.69,9.69,0,0,0,16,44a11.2,11.2,0,0,0,5.85-1.72h.46a17.44,17.44,0,0,0,12.21-4.78,15.42,15.42,0,0,0,4.84-11.18C39.39,16.59,25.54,0,22.61,0S5.75,16.59,5.75,26.33a14.48,14.48,0,0,0,.74,4.57A1.8,1.8,0,0,0,5,31.38a2,2,0,0,0-.33,1.76C5.47,38.56,8.56,42.29,13.38,43.64Zm.53-1.93A9.59,9.59,0,0,1,7,34.32a47.93,47.93,0,0,0,11.88,7.19A8.37,8.37,0,0,1,13.91,41.71ZM23,39.59c.23-1.07.57-2.73,1.1-5.28a30.63,30.63,0,0,0,.7-8.3,11.93,11.93,0,0,1,2.29,7A7.92,7.92,0,0,1,23,39.59Zm-.17-13.16a29.09,29.09,0,0,1-.69,7.47c-.61,3-1,4.69-1.19,5.72a6.53,6.53,0,0,1-2.06-5.88c.29-2.64,1.92-4.71,3.23-6.38C22.36,27,22.6,26.73,22.82,26.43Zm-6,8.37a8.83,8.83,0,0,0,1,4.1A48.42,48.42,0,0,1,8.57,33c.61,0,1.32.06,2.19.06A10.21,10.21,0,0,1,16.84,34.8ZM22.61,2C25,3.15,37.39,17.49,37.39,26.33a13.43,13.43,0,0,1-4.23,9.74,15,15,0,0,1-7.25,3.82A9.64,9.64,0,0,0,29.08,33c.2-4.76-3.3-10.13-5.37-10.42a1.44,1.44,0,0,0-1.65,1.18,8.76,8.76,0,0,1-1.53,2.33,15.87,15.87,0,0,0-3.47,6.46,13.06,13.06,0,0,0-6.3-1.49c-.82,0-1.5,0-2.08-.05a12.64,12.64,0,0,1-.93-4.72C7.75,17.49,20.17,3.14,22.61,2Z"/>
    </svg>
    );
}

export default IconNaturalOrigin
