import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import GatsbyDataInterface from '../../@types/GatsbyImage';
import useOnScreen from '../../hooks/useOnScreen';

interface Props extends GatsbyDataInterface {
    animate?: boolean;
}

const BackgroundImage: React.FC<Props> = (props) => {
    const { title, gatsbyImageData, animate } = props;

    const ref = React.useRef(null);
    const onScreen = useOnScreen(ref);

    const variants = {
        final: {
            scale: 1,
            transition: {
                duration: 8
            }
        },
        init: {
            scale: 1.10,
            transition: {
                duration: 2
            }
        }
    }

    const shouldAnimate = animate !== undefined ? animate : true;

    return (
        <div className="section-background-image" ref={ref} >
            <motion.div
                variants={variants}
                initial={shouldAnimate ? variants.init : variants.final}
                animate={(shouldAnimate && onScreen) && variants.final}
                exit={variants.init}
                className="section-background-image__motion">
                <GatsbyImage
                    image={{...gatsbyImageData, layout: "fullWidth"}}
                    alt={title}
                    style={{height: "100%"}}/>
            </motion.div>
        </div>
    )
}

export default BackgroundImage
