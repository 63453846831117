import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconShortComposition: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M38.13.94A3.16,3.16,0,0,0,33,4.52L17.44,18H3.33A3.5,3.5,0,0,0,2.4,24.9c.37,5.75,4.33,11.81,9.94,13.74v2.71A2.64,2.64,0,0,0,15,44H28.86a2.64,2.64,0,0,0,2.63-2.64v-2.7c5.65-1.92,9.65-8,10-13.76a3.5,3.5,0,0,0-1-6.86H33.14l6.48-6.9a3.16,3.16,0,0,0,3.62-5.08ZM29.49,41.35a.64.64,0,0,1-.63.64H15a.64.64,0,0,1-.63-.64V39.14a12.36,12.36,0,0,0,1.73.14H27.8a10.42,10.42,0,0,0,1.69-.14ZM42,21.53A1.49,1.49,0,0,1,40.51,23a1.06,1.06,0,0,0-1,1.05c0,6.11-5.14,13.21-11.7,13.21H16.07C9.84,37.28,4.92,30.9,4.41,25h10.2a1,1,0,0,0,0-2H3.33a1.5,1.5,0,1,1,0-3H40.5A1.5,1.5,0,0,1,42,21.53ZM41.83,9.11a1.16,1.16,0,0,1-1.64,0,1,1,0,0,0-.73-.29,1,1,0,0,0-.71.31L30.39,18H20.5l13.81-12L35.9,7.65a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.41L35.07,4a1.16,1.16,0,0,1,1.64-1.64l5.12,5.12A1.16,1.16,0,0,1,41.83,9.11Z"/>
    </svg>
    );
}

export default IconShortComposition
