import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconProtectiveFilm: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M3.92,29.89a1.2,1.2,0,0,0-2.2,1,22.12,22.12,0,0,0,2.05,3.68,1.19,1.19,0,0,0,1,.52,1.14,1.14,0,0,0,.68-.21,1.2,1.2,0,0,0,.31-1.67A19.58,19.58,0,0,1,3.92,29.89Z"/>
        <path style={{fill: color}} d="M29.79,3.87a19.26,19.26,0,0,1,3.3,1.81,1.2,1.2,0,0,0,.67.21,1.22,1.22,0,0,0,1-.53,1.21,1.21,0,0,0-.32-1.67,22.52,22.52,0,0,0-3.7-2,1.2,1.2,0,1,0-.95,2.21Z"/>
        <path style={{fill: color}} d="M38.88,11.79a1.21,1.21,0,0,0,1,.58,1.2,1.2,0,0,0,1-1.82,21.29,21.29,0,0,0-2.52-3.39,1.2,1.2,0,0,0-1.69-.09,1.21,1.21,0,0,0-.09,1.7A19.42,19.42,0,0,1,38.88,11.79Z"/>
        <path style={{fill: color}} d="M1.45,17.84a1.15,1.15,0,0,0,.31,0A1.2,1.2,0,0,0,2.92,17a19.87,19.87,0,0,1,1.29-3.53,1.2,1.2,0,1,0-2.16-1,21.17,21.17,0,0,0-1.45,4A1.19,1.19,0,0,0,1.45,17.84Z"/>
        <path style={{fill: color}} d="M1.38,26.8l.21,0a1.19,1.19,0,0,0,1-1.39A19.88,19.88,0,0,1,2.27,22v-.38A1.21,1.21,0,0,0,1.1,20.42h0a1.2,1.2,0,0,0-1.2,1.17V22A21.85,21.85,0,0,0,.2,25.81,1.2,1.2,0,0,0,1.38,26.8Z"/>
        <path style={{fill: color}} d="M21.52,2.27H22a19.41,19.41,0,0,1,2.16.12c.39,0,.77.09,1.15.16h.2a1.18,1.18,0,0,0,1.18-1,1.2,1.2,0,0,0-1-1.38L24.39,0a21.94,21.94,0,0,0-2.92-.12,1.2,1.2,0,1,0,.05,2.39Z"/>
        <path style={{fill: color}} d="M40.82,16.07a19.29,19.29,0,0,1,.78,3.68,1.2,1.2,0,0,0,1.19,1.06h.14A1.21,1.21,0,0,0,44,19.48a22.33,22.33,0,0,0-.88-4.13,1.2,1.2,0,1,0-2.29.72Z"/>
        <path style={{fill: color}} d="M8.85,36.71a1.2,1.2,0,0,0-1.6,1.79A22.69,22.69,0,0,0,10.66,41a1.22,1.22,0,0,0,.61.17,1.19,1.19,0,0,0,1-.59,1.2,1.2,0,0,0-.41-1.65A19.46,19.46,0,0,1,8.85,36.71Z"/>
        <path style={{fill: color}} d="M12.82,4.39a1.24,1.24,0,0,0,.53-.12,19.43,19.43,0,0,1,3.52-1.33A1.2,1.2,0,1,0,16.25.63a21.71,21.71,0,0,0-4,1.48,1.2,1.2,0,0,0,.53,2.28Z"/>
        <path style={{fill: color}} d="M5.82,9.93a1.18,1.18,0,0,0,.92-.44A19.74,19.74,0,0,1,9.4,6.82,1.2,1.2,0,0,0,7.87,5a22.16,22.16,0,0,0-3,3,1.19,1.19,0,0,0,.17,1.69A1.15,1.15,0,0,0,5.82,9.93Z"/>
        <path style={{fill: color}} d="M16.18,40.85a1.2,1.2,0,0,0-.7,2.3,22.27,22.27,0,0,0,4.13.85h.13a1.19,1.19,0,0,0,1.19-1.07,1.21,1.21,0,0,0-1.06-1.33A19.35,19.35,0,0,1,16.18,40.85Z"/>
        <path style={{fill: color}} d="M22,33.45A11.45,11.45,0,1,0,10.55,22,11.47,11.47,0,0,0,22,33.45Zm0-20.9A9.45,9.45,0,1,1,12.55,22,9.46,9.46,0,0,1,22,12.55Z"/>
    </svg>
    );
}

export default IconProtectiveFilm
