import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconImplementation: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M33,11.84h-.36v-3a1.12,1.12,0,0,0-1.07-1.07H30.53V3.35A2.31,2.31,0,0,0,28.22,1H24.29A2.32,2.32,0,0,0,22,3.35v4.4h-.25a1.88,1.88,0,0,0-1.87,1.88V12a3.35,3.35,0,0,0-2.5,3.23V40.67A3.35,3.35,0,0,0,20.7,44H33a3.35,3.35,0,0,0,3.34-3.35V15.19A3.35,3.35,0,0,0,33,11.84ZM23,9.75a1,1,0,0,0,1-1V7h.08a1.43,1.43,0,0,0,0-2.86H24V3.35A.31.31,0,0,1,24.29,3h3.93a.31.31,0,0,1,.31.31v5.4a1,1,0,0,0,1,1h1.13v2.09h-8.8V9.75ZM34.36,40.67A1.35,1.35,0,0,1,33,42H20.7a1.35,1.35,0,0,1-1.34-1.35V15.19a1.35,1.35,0,0,1,1.34-1.35H33a1.35,1.35,0,0,1,1.34,1.35Z"/>
        <path style={{fill: color}} d="M30.69,16a1,1,0,0,0-1,1V39.45a1,1,0,0,0,2,0V17A1,1,0,0,0,30.69,16Z"/>
        <circle style={{fill: color}} cx="18.36" cy="7.03" r="1.01"/>
        <path style={{fill: color}} d="M6.88,6a1,1,0,1,0,1,1A1,1,0,0,0,6.88,6Z"/>
        <circle style={{fill: color}} cx="14.2" cy="4.52" r="1.01"/>
        <circle style={{fill: color}} cx="9.92" cy="2.52" r="1.01"/>
        <circle style={{fill: color}} cx="5.64" cy="1.01" r="1.01"/>
        <circle style={{fill: color}} cx="5.64" cy="13.05" r="1.01"/>
        <circle style={{fill: color}} cx="9.92" cy="11.54" r="1.01"/>
        <path style={{fill: color}} d="M14.2,8.52a1,1,0,1,0,1,1A1,1,0,0,0,14.2,8.52Z"/>
        <circle style={{fill: color}} cx="2.83" cy="4.53" r="1.01"/>
        <circle style={{fill: color}} cx="2.83" cy="9.65" r="1.01"/>
    </svg>
    );
}

export default IconImplementation
