const TRANSITIONS = {
    spring: {
        type: "spring",
        stiffness: 170,
        damping: 25,
        duration: 0.3
    },
    tween: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.3
    }
};

export default TRANSITIONS;