import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconSubstanceLoweringThePh: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M16.51,19.07,22,9.54,16.51,0H5.5L0,9.54l5.5,9.53ZM6.66,2h8.69L19.7,9.54l-4.35,7.53H6.66L2.31,9.54Z"/>
        <path style={{fill: color}} d="M27.49,12.91,22,22.44,27.49,32h11L44,22.44l-5.5-9.53ZM37.34,30h-8.7L24.3,22.44l4.34-7.53h8.7l4.35,7.53Z"/>
        <path style={{fill: color}} d="M2.37,34.46,7.87,44h11l5.5-9.53-5.5-9.53h-11Zm15.35-7.53,4.35,7.53L17.72,42H9L4.68,34.46,9,26.93Z"/>
    </svg>
    );
}

export default IconSubstanceLoweringThePh
