import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconWater: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M22,44A16.56,16.56,0,0,0,38.54,27.45c0-6.23-4.27-11.71-9.22-18.06C27.13,6.58,24.86,3.68,22.84.53A1,1,0,0,0,22,.07a1,1,0,0,0-.85.52A78,78,0,0,1,14,10.77C9.6,16.39,5.46,21.69,5.46,27.45A16.56,16.56,0,0,0,22,44ZM15.57,12a92.24,92.24,0,0,0,6.49-9c1.82,2.7,3.78,5.2,5.68,7.64,4.72,6.06,8.8,11.28,8.8,16.83a14.54,14.54,0,0,1-29.08,0C7.46,22.38,11.4,17.34,15.57,12Z"/>
        <path style={{fill: color}} d="M21,39.32A12.63,12.63,0,0,0,33.59,26.7a1,1,0,0,0-2,0A10.63,10.63,0,0,1,21,37.32a1,1,0,0,0,0,2Z"/>
    </svg>
    );
}

export default IconWater
