import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconFragranceFree: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M35.81,6.22c-3.24,0-6,1.46-7.28,3.55H24.1l-.08,0A3,3,0,0,0,22,9h-.19V3A3,3,0,0,0,18.9,0H15.06a3,3,0,0,0-3,3V9h-.19a3.08,3.08,0,0,0-3.07,3.07v.17a3,3,0,0,0,.7,1.91,16.73,16.73,0,0,0-9,18.51v0A16.78,16.78,0,0,0,8.8,43.86L9,44h15.9l.23-.13a16.77,16.77,0,0,0,.06-29.25h3.3c1.24,2.1,4,3.57,7.29,3.57,4.4,0,8-2.69,8-6S40.21,6.22,35.81,6.22ZM14.11,3a1,1,0,0,1,1-1H18.9a1,1,0,0,1,1,1V9H14.11Zm-3.26,9.15A1.07,1.07,0,0,1,11.92,11H22a1.06,1.06,0,0,1,1.06,1.07v.17A1.06,1.06,0,0,1,22,13.34H11.92a1.07,1.07,0,0,1-1.07-1.06ZM24.4,42H9.55a14.79,14.79,0,0,1-6.7-8.51,14.44,14.44,0,0,0,4-1.73c3.39-2,5.48-2.4,9.66,0s7.6,2.45,11.49,0A15.58,15.58,0,0,1,31.72,30,14.78,14.78,0,0,1,24.4,42Zm7.28-14.1a16.62,16.62,0,0,0-4.78,2.17c-3.25,2.05-5.8,2-9.42,0-5-2.85-7.82-2.23-11.66,0A12.81,12.81,0,0,1,2.4,31.53a14.72,14.72,0,0,1,9.47-16.18H22.09l.06,0A14.85,14.85,0,0,1,31.68,27.89ZM25.07,12.61c0-.11,0-.22,0-.33v-.17c0-.12,0-.23,0-.34h2.79c0,.14,0,.28,0,.43s0,.28,0,.41Zm10.74,3.57c-3.24,0-6-1.83-6-4s2.74-4,6-4,6,1.82,6,4S39.05,16.18,35.81,16.18Z"/>
        <path style={{fill: color}} d="M4.47,6.68H7.75a1,1,0,0,0,0-2H4.47a1,1,0,0,0,0,2Z"/>
        <path style={{fill: color}} d="M4.84,2.22,7.78,3.66a1,1,0,0,0,.43.1,1,1,0,0,0,.44-1.9L5.72.42A1,1,0,0,0,4.38.88,1,1,0,0,0,4.84,2.22Z"/>
        <path style={{fill: color}} d="M7.78,7.7,4.84,9.15A1,1,0,0,0,5.28,11a1,1,0,0,0,.44-.1L8.65,9.5a1,1,0,0,0-.87-1.8Z"/>
        <path style={{fill: color}} d="M16.82,3.42A1.88,1.88,0,1,0,18.69,5.3,1.88,1.88,0,0,0,16.82,3.42ZM16.69,5.3a.13.13,0,1,1,.26,0C17,5.44,16.69,5.44,16.69,5.3Z"/>
    </svg>
    );
}

export default IconFragranceFree
