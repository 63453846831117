import { motion } from 'framer-motion';
import React from 'react';
import uuid from 'react-uuid';
import TRANSITIONS from '../../animations/transitions';

interface Props {
  visible?: boolean;
  text: string;
  delay?: number;
}

const AnimatedText: React.FC<Props> = (props) => {
  const { text, delay, visible } = props;
  const [words, setWords] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (words.length > 0) return;
    if (text) setWords(text.split(" "));
  }, []);

  const sentenceVariants = {
    visible: {
      opacity: 1,
      transition: {
        ...TRANSITIONS.spring,
        delay: 0.3,
        staggerChildren: 0.03,
        delayChildren: delay || 0.4
      }
    },
    hidden: {
      opacity: 1,
      transition: {
        delay: 0.5,
        delayChildren: 0.5
      }
    }
  }

  const wordVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ...TRANSITIONS.spring
      }
    },
    hidden: {
      opacity: 0,
      y: 100,
      transition: {
        ...TRANSITIONS.spring
      }
    }
  }

  return (
    <React.Fragment>
      {words && (
        <motion.span variants={sentenceVariants} initial="hidden" exit="visible" animate={visible ? "visible" : "hidden"} className="animated-text">
          {words.map((word, i) => {
            return (
              <motion.span key={uuid()} style={{ display: 'inline-block', overflow: "hidden" }}>
                <motion.span
                  variants={wordVariants}
                  style={{ display: 'inline-block', willChange: 'transform' }}
                  custom={i}
                  key={`${word}-${i}-${uuid()}`}
                >
                  {word + (i !== words.length - 1 ? '\u00A0' : '')}
                </motion.span>
              </motion.span>
            )
          })}
        </motion.span>
      )}
    </React.Fragment>
  )
}

export default React.memo(AnimatedText);

