import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconWriteToUs: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M43.28,15.66,28.34.72a2.77,2.77,0,0,0-3.82,0L.72,24.52a2.7,2.7,0,0,0,0,3.82L15.66,43.28a2.7,2.7,0,0,0,3.82,0l23.8-23.8a2.7,2.7,0,0,0,0-3.82ZM41.69,16.9,26,18.15,27.28,2.49ZM25.25,2.82,23.64,22.48a1.36,1.36,0,0,1-1.25,1.26L2.77,25.3ZM2.53,27.32l15-1.19-1.22,15ZM18.27,41.66,19.55,26l3-.24a3.35,3.35,0,0,0,3.1-3.09l.2-2.47L41,19Z"/>
    </svg>
    );
}

export default IconWriteToUs
