import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconChoiceOfTechnology: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M.21,18.28a8.28,8.28,0,1,0,16.11-1.12A12.26,12.26,0,0,1,28.69,2.32h0s1,.07,1.06-.41-.54-.65-.78-.73A22,22,0,0,0,.31,17.76h0C.27,17.93.24,18.1.21,18.28Z"/>
        <path style={{fill: color}} d="M41.71,12.45a.59.59,0,0,0-.69-.31.54.54,0,0,0-.31.67h0c.82,4.64-.88,10.1-5.58,15.9C23.56,43,8.35,33.89,3,30.77a1,1,0,0,0-1,0c-.17.12-.13.35-.07.51A22,22,0,1,0,41.71,12.45Z"/>
        <path style={{fill: color}} d="M15,29.06c-.26.16-.61.42-.61.69s.68,1.07.86,1.24a11.72,11.72,0,1,0,8.53-20.66c-2.52-.19-3.28.86-3.47,1.24a11.63,11.63,0,0,0-.55,6.27A9.9,9.9,0,0,1,16.47,28.1a11,11,0,0,1-1.14.79h0Z"/>
    </svg>
    );
}

export default IconChoiceOfTechnology
