import React from 'react';
import { PageContext } from '../../@types/PageContext';
import Container from '../Container/Container';
import Logo from '../Logo/Logo';
import Hero from '../LANDING/Hero/Hero';
import SwitchLocale from '../SwitchLocale/SwitchLocale';
import { Helmet } from 'react-helmet';

interface Props {
    pageContext: PageContext;
}

const Header: React.FC<Props> = (props) => {
    const { pageContext } = props;
    return (
        <header className="site-header">
            <Container display="flex" className="site-header--items" maxWidth="xl" wrapperClassName="display--flex flex--space-between">
                <Logo dark caption={pageContext?.content?.logo?.caption} to={pageContext.slug || '/'} />
                <SwitchLocale pageContext={pageContext} />
            </Container>
            {pageContext?.content && <Hero pageContext={pageContext} />}
            <Helmet
                title={`ChitoVelum® PRO ${pageContext?.content?.logo?.caption}`}
                htmlAttributes={{
                    lang: pageContext.locale,
                }}
            />
        </header>
    );
}

export default Header;
