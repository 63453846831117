import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from "../../Container/Container";
import uuid from 'react-uuid';
import SwiperCore, { Navigation, A11y, Autoplay, EffectFade, Pagination } from 'swiper';
import AnimatedText from "../../Animated/AnimatedText";
import { motion } from "framer-motion";
import ANIMATIONS from "../../../animations/animations";
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/pagination/pagination.scss';
import Button from "../../Button/Button";
import Typography from "../../Typography/Typography";
import { RootProps } from "../../../@types/PageContext";

SwiperCore.use([Navigation, A11y, Autoplay, EffectFade, Pagination]);

interface SlideProps {
    title: string;
    isActive: boolean;
}
const Slide: React.FC<SlideProps> = (props) => {
    const { isActive, title } = props;

    return (
        <div>
            <h1><AnimatedText visible={isActive} text={title} /></h1>
            <motion.div
                variants={ANIMATIONS.button}
                initial={ANIMATIONS.button.hidden}
                exit={ANIMATIONS.button.hidden}
                animate={isActive ? ANIMATIONS.button.visible : ANIMATIONS.button.hidden}>
            </motion.div>
        </div>
    );
}

const Hero: React.FC<RootProps> = props => {
    const { pageContext } = props;
    const SLIDES = pageContext.content.hero.slides;

    const { presentationButton, presentationCaption } = pageContext.content.hero;
    const { presentationURL } = pageContext.content.file;

    return (
        <Container className="hero-carousel" wrapperClassName="hero-wrapper" maxWidth="xl">
            <CTADownload
                presentationButton={presentationButton}
                presentationCaption={presentationCaption}
                presentationURL={presentationURL} />
            <div className="swiper-container-wrapper">
                <Swiper
                    loop
                    speed={100}
                    effect="fade"
                    pagination={{
                        el: '.swiper-pagination',
                        clickable: true,
                        renderBullet: function (index, className) {
                            return `<span class="${className}"></span>`;
                        }
                    }}
                    autoplay={{
                        delay: 4500,
                    }}
                    slidesPerView={1}>
                    {React.Children.map(SLIDES, child => {
                        return (
                            <SwiperSlide key={uuid()} className="hero-carousel-slide">
                                {({ isActive }) => (
                                    <Slide title={child} isActive={isActive} />
                                )}
                            </SwiperSlide>)
                    })}
                </Swiper>
            </div>
            <div className="swiper-pagination swiper-pagination-timeline-page" />
        </Container>
    );
}

export default React.memo(Hero);

interface CTADownloadProps {
    presentationURL: string;
    presentationButton: string;
    presentationCaption: string;
}

export const CTADownload: React.FC<CTADownloadProps> = props => {
    const { presentationURL, presentationButton, presentationCaption } = props;

    return (
        <div className="cta-download">
            <Button size="sm" link={presentationURL} color="brand-2">{presentationButton}</Button>
            <Typography variant="caption" color="secondary">{presentationCaption}</Typography>
        </div>
    );
}