import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconAdditionToTheProduct: React.FC<IconProps> = (props) => {
    const { color } = props;

    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M33.82,19.54a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.41l3.67,3.67a2.87,2.87,0,0,1,0,4,1,1,0,0,0,1.41,1.42,4.86,4.86,0,0,0,0-6.87Z"/>
        <path style={{fill: color}} d="M33.37.7A3,3,0,0,0,29.3.7L24.76,5.25,23.59,4.08a4,4,0,0,0-5.54,0L7.89,14.24,1.76,20.37A2.27,2.27,0,0,0,2.35,24l14.87,7.37L24,38.13a7.52,7.52,0,0,0,10.59,0l6.25-6.26a7.48,7.48,0,0,0,0-10.58l-.42-.42L43.49,14a3.46,3.46,0,0,0-.7-3.86ZM3.24,22.22a.28.28,0,0,1-.15-.2.26.26,0,0,1,.08-.23l2.6-2.6a3.85,3.85,0,0,0,1,1.73l6,6Zm36.15,8.24-6.26,6.25a5.49,5.49,0,0,1-7.75,0L8.17,19.5a1.92,1.92,0,0,1,0-2.71l.93-.93.53-.53h0l9.83-9.84a2,2,0,0,1,2.72,0L39.39,22.71A5.5,5.5,0,0,1,39.39,30.46ZM28.73,9.22l2.64-2.65,6.29,6.3L36,16.51Zm12.94,3.93-2.8,6.21L37.53,18l2.14-4.74a1.36,1.36,0,0,0-.27-1.51L32.08,4.45a1,1,0,0,0-1.42,0L27.31,7.8,26.17,6.66l4.55-4.54a.89.89,0,0,1,1.24,0l9.41,9.41A1.44,1.44,0,0,1,41.67,13.15Z"/>
        <path style={{fill: color}} d="M4.13,32.2A1.22,1.22,0,0,0,3,32.88C1.75,35.44.2,38.8.2,40.21a3.94,3.94,0,0,0,7.87,0c0-1.42-1.55-4.77-2.84-7.33A1.22,1.22,0,0,0,4.13,32.2Zm0,9.94A1.94,1.94,0,0,1,2.2,40.21a19.74,19.74,0,0,1,1.93-5,20.5,20.5,0,0,1,1.94,5A1.94,1.94,0,0,1,4.14,42.14Z"/>
    </svg>
    );
}

export default IconAdditionToTheProduct
