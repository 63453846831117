import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconTastelessOdorlessColorless: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M36.48,36.51H44V0H7.5V7.53H0V44H36.48Zm0-2v-.42L42,28.6v5.91ZM42,25.77l-5.49,5.49v-5.6L42,20.18Zm0-8-.19-.19-5.3,5.3v-5.6L42,11.75Zm0-8.8-5.49,5.48V8.94L42,3.45ZM9.5,18.92l0,0L19,9.53h5.59L9.5,24.58ZM27.38,9.53h5.68L9.5,33.09V27.41Zm7.68-2H29.38L34.87,2h5.68Zm-8.51,0H21l5.45-5.46,0,0H32Zm-8.42,0H12.54L18,2h5.6Zm-2,2L9.5,16.16v-5.6l1-1Zm18.35,1.41v5.47L16.39,34.51H10.91Zm0,8.3v5.6L24.9,34.42l.09.09H19.21Zm0,8.42v5.6l-1.25,1.25H27.64ZM9.5,2h5.7L9.71,7.53H9.5ZM2,42V9.53H7.5v27h27V42Z"/>
    </svg>
    );
}

export default IconTastelessOdorlessColorless
