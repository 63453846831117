import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconTestedOnHumans: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M22,22.9a6.45,6.45,0,1,1,6.49-6.34A6.44,6.44,0,0,1,22,22.9"/>
        <path style={{fill: color}} d="M32.9,30.43a8.13,8.13,0,0,0-6.63-7,.89.89,0,0,0-.55.09,7.82,7.82,0,0,1-3.76.93,7.94,7.94,0,0,1-3.7-.95,1,1,0,0,0-.59-.09,7.08,7.08,0,0,0-2.2.79,8,8,0,0,0-4.44,7c-.07,1.09,0,6.64,0,7.83,0,.21,0,.38.27.46A28.88,28.88,0,0,0,22,41.25a25.91,25.91,0,0,0,10.62-1.87.56.56,0,0,0,.39-.61C33,37.84,33,31.93,32.9,30.43Z"/>
        <path style={{fill: color}} d="M11,16.31a6.45,6.45,0,1,1,6.49-6.59.49.49,0,0,1-.2.45,11.65,11.65,0,0,0-1.87,2.3,9.34,9.34,0,0,0-.92,2.62.5.5,0,0,1-.24.37A6.34,6.34,0,0,1,11,16.31Z"/>
        <path style={{fill: color}} d="M16.54,21.94a8,8,0,0,1-2.1-4.29c0-.13,0-.36-.38-.19a7.56,7.56,0,0,1-3.08.6,7.94,7.94,0,0,1-3.71-.94A.91.91,0,0,0,6.68,17a7.26,7.26,0,0,0-2.19.78,8,8,0,0,0-4.45,7C0,25.85,0,30.41,0,31.59c0,.21,0,.38.27.46a29.15,29.15,0,0,0,8.8,1.82c.24,0,.69,0,.68-1.09-.06-3.49.94-8.19,6.67-10.46C16.54,22.28,16.78,22.19,16.54,21.94Z"/>
        <path style={{fill: color}} d="M33,16.31a6.45,6.45,0,1,0-6.49-6.59.49.49,0,0,0,.2.45,11.25,11.25,0,0,1,1.86,2.3,9.07,9.07,0,0,1,.93,2.62.5.5,0,0,0,.24.37A6.34,6.34,0,0,0,33,16.31Z"/>
        <path style={{fill: color}} d="M27.46,21.94a7.87,7.87,0,0,0,2.09-4.29c0-.13.05-.36.39-.19a7.56,7.56,0,0,0,3.08.6,8,8,0,0,0,3.71-.94.91.91,0,0,1,.59-.09,7.26,7.26,0,0,1,2.19.78,8,8,0,0,1,4.45,7c.06,1.08,0,5.64,0,6.82,0,.21,0,.38-.27.46a29.15,29.15,0,0,1-8.8,1.82c-.24,0-.69,0-.69-1.09.07-3.49-.93-8.19-6.66-10.46C27.46,22.28,27.22,22.19,27.46,21.94Z"/>
    </svg>
    );
}

export default IconTestedOnHumans
