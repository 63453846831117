import clsx from 'clsx';
import React from 'react'
import IconAdditionToTheProduct from '../Icons/IconAdditionToTheProduct';
import IconBactericidal from '../Icons/IconBactericidal';
import IconChitosan from '../Icons/IconChitosan';
import IconChoiceOfTechnology from '../Icons/IconChoiceOfTechnology';
import IconChoiceOfTechnology2 from '../Icons/IconChoiceOfTechnology2';
import IconCO2 from '../Icons/IconCO2';
import IconDevelopASolution from '../Icons/IconDevelopASolution';
import IconForAllSkinTypes from '../Icons/IconForAllSkinTypes';
import IconFragranceFree from '../Icons/IconFragranceFree';
import IconImplementation from '../Icons/IconImplementation';
import IconInjection from '../Icons/IconInjection';
import IconNaturalOrigin from '../Icons/IconNaturalOrigin';
import IconNaturalThickener from '../Icons/IconNaturalThickener';
import IconNoPreservatives from '../Icons/IconNoPreservatives';
import IconBrushing from '../Icons/IconPainting';
import IconProductOnTheMarket from '../Icons/IconProductOnTheMarket';
import IconProtectiveFilm from '../Icons/IconProtectiveFilm';
import IconShortComposition from '../Icons/IconShortComposition';
import IconSpray from '../Icons/IconShower';
import IconSimpleImplementation from '../Icons/IconSimpleImplementation';
import IconSubstanceLoweringThePh from '../Icons/IconSubstanceLoweringThePh';
import IconTastelessOdorlessColorless from '../Icons/IconTastelessOdorlessColorless';
import IconTestedOnHumans from '../Icons/IconTestedOnHumans';
import IconWater from '../Icons/IconWater';
import IconWriteToUs from '../Icons/IconWriteToUs';
import PhoneIcon from '@material-ui/icons/Phone';
import InfoIcon from '@material-ui/icons/Info';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import { CropDinOutlined } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconLinkedIn from '../Icons/Social/IconLinkedIn';
import IconFood from '../Icons/IconFood';
import IconCosmetics from '../Icons/IconCosmetics';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import IconNatural100 from '../Icons/IconNatural100';
import IconQuality from '../Icons/IconQuality';
import IconScience from '../Icons/IconScience';

export interface IconProps {
    color?: string;
}

export const ICON_DEFAULT_COLOR = "#05070A";

export enum ICONS {
    CHEVRON_LEFT = "chevron-left",
    CHEVRON_RIGHT = "chevron-right",
    PHONE = "phone",
    INFO = "info",
    MAP_PIN = "map-pin",
    EMAIL = "email",
    ADDITION_TO_THE_PRODUCT = "addition-to-the-product",
    BACKTERIAL = "backterial",
    CHITOSAN = "chitosan",
    CHOICE_OF_TECHNOLOGY = "choice-of-technology",
    CHOICE_OF_TECHNOLOGY2 = "choice-of-technology-2",
    CO2 = "co2",
    DEVELOP_A_SOLUTION = "develop-a-solution",
    FOR_ALL_SKIN_TYPES = "for-all-skin-types",
    FRAGRANCE_FREE = "fragrance-free",
    IMPLEMENTATION = "implementation",
    INJECTION = "injection",
    NATURAL_ORIGIN = "natural-origin",
    NATURAL_THICKENER = "natural-thickener",
    NO_PRESERVATIVES = "no-preservatives",
    BRUSHING = "brushing",
    PRODUCT_ON_THE_MARKET = "product-on-the-market",
    PROTECTIVE_FILM = "protective-film",
    SHORT_COMPOSITION = "short-composition",
    SPRAY = "spray",
    SIMPLE_IMPLEMENTATION = "simple-implementation",
    SUBSTANCE_LOWERING_THE_PH = "substance-lowering-the-ph",
    TASTELESS_ODORLESS_COLORLESS = "tasteless-odorless-colorless",
    TESTED_ON_HUMANS = "tested-on-humans",
    WATER = "water",
    WRITE_TO_US = "write-to-us",
    LINKEDIN = "linkedin",
    FOOD = "food",
    COSMETICS = "cosmetics",
    BACTERICIDAL = "bactericidal",
    QUOTE = "format_quote",
    QUALITY = "quality",
    NATURAL_100 = "natural-100",
    SCIENCE = "science"
}

export interface IconInterface extends IconProps {
    iconName: ICONS;
    small?: boolean;
}

interface IconComponent {
    component: React.ReactNode;
    material?: boolean;
}

const Icon: React.FC<IconInterface> = (props) => {
    const { iconName, small } = props;
    const color = props.color || ICON_DEFAULT_COLOR;

    const ICONS: { [key: string]: IconComponent } = {
        "addition-to-the-product": { component: <IconAdditionToTheProduct color={color} /> },
        "bactericidal": { component: <IconBactericidal color={color} /> },
        "chitosan": { component: <IconChitosan color={color} /> },
        "choice-of-technology": { component: <IconChoiceOfTechnology color={color} /> },
        "choice-of-technology-2": { component: <IconChoiceOfTechnology2 color={color} /> },
        "co2": { component: <IconCO2 color={color} /> },
        "develop-a-solution": { component: <IconDevelopASolution color={color} /> },
        "for-all-skin-types": { component: <IconForAllSkinTypes color={color} /> },
        "fragrance-free": { component: <IconFragranceFree color={color} /> },
        "implementation": { component: <IconImplementation color={color} /> },
        "injection": { component: <IconInjection color={color} /> },
        "natural-origin": { component: <IconNaturalOrigin color={color} /> },
        "natural-thickener": { component: <IconNaturalThickener color={color} /> },
        "no-preservatives": { component: <IconNoPreservatives color={color} /> },
        "brushing": { component: <IconBrushing color={color} /> },
        "product-on-the-market": { component: <IconProductOnTheMarket color={color} /> },
        "protective-film": { component: <IconProtectiveFilm color={color} /> },
        "short-composition": { component: <IconShortComposition color={color} /> },
        "spray": { component: <IconSpray color={color} /> },
        "simple-implementation": { component: <IconSimpleImplementation color={color} /> },
        "substance-lowering-the-ph": { component: <IconSubstanceLoweringThePh color={color} /> },
        "tasteless-odorless-colorless": { component: <IconTastelessOdorlessColorless color={color} /> },
        "tested-on-humans": { component: <IconTestedOnHumans color={color} /> },
        "water": { component: <IconWater color={color} /> },
        "write-to-us": { component: <IconWriteToUs color={color} /> },
        "linkedin": { component: <IconLinkedIn /> },
        "food": { component: <IconFood /> },
        "cosmetics": { component: <IconCosmetics /> },
        // material
        "chevron-left": {
            component: <ChevronLeftIcon style={{ fill: color }} />,
            material: true
        },
        "chevron-right": {
            component: <ChevronRightIcon style={{ fill: color }} />,
            material: true
        },
        "info": {
            component: <InfoIcon style={{ fill: color }} />,
            material: true
        },
        "map-pin": {
            component: <RoomIcon style={{ fill: color }} />,
            material: true
        },
        "email": {
            component: <EmailIcon style={{ fill: color }} />,
            material: true
        },
        "phone": {
            component: <PhoneIcon style={{ fill: color }} />,
            material: true
        },
        "format_quote": {
            component: <FormatQuoteIcon style={{ fill: color }} />,
            material: true
        },
        "natural-100": {
            component: <IconNatural100 color={color} />
        },
        "quality": {
            component: <IconQuality color={color} />
        },
        "science": {
            component: <IconScience color={color} />
        }
    };

    // material icon
    if (ICONS[iconName] && ICONS[iconName].material) return <>{ICONS[iconName].component}</>;
    return (
        <span className={clsx("icon", small && "icon--small")}>
            {ICONS[iconName] ? ICONS[iconName].component : <CropDinOutlined style={{ fill: color }} />}
        </span>
    );
}

export default Icon;
