import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconChoiceOfTechnology2: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M18.26,3.8A3.74,3.74,0,1,0,22,.06,3.75,3.75,0,0,0,18.26,3.8Zm5.48,0A1.74,1.74,0,1,1,22,2.06,1.74,1.74,0,0,1,23.74,3.8Z"/>
        <path style={{fill: color}} d="M22,36.58a3.74,3.74,0,1,0,3.74,3.74A3.74,3.74,0,0,0,22,36.58Zm0,5.48a1.74,1.74,0,1,1,1.74-1.74A1.74,1.74,0,0,1,22,42.06Z"/>
        <path style={{fill: color}} d="M40.26,18.32A3.74,3.74,0,1,0,44,22.06,3.75,3.75,0,0,0,40.26,18.32Zm0,5.48A1.74,1.74,0,1,1,42,22.06,1.74,1.74,0,0,1,40.26,23.8Z"/>
        <path style={{fill: color}} d="M7.48,22.06A3.74,3.74,0,1,0,3.74,25.8,3.74,3.74,0,0,0,7.48,22.06ZM3.74,23.8a1.74,1.74,0,1,1,1.74-1.74A1.74,1.74,0,0,1,3.74,23.8Z"/>
        <path style={{fill: color}} d="M32.27,6.5a3.74,3.74,0,0,0,0,5.29,3.7,3.7,0,0,0,2.64,1.1A3.74,3.74,0,1,0,32.27,6.5Zm3.87,1.42a1.74,1.74,0,1,1-1.23-.51A1.74,1.74,0,0,1,36.14,7.92Z"/>
        <path style={{fill: color}} d="M6.44,32.32A3.75,3.75,0,0,0,5.35,35a3.74,3.74,0,1,0,6.38-2.65A3.84,3.84,0,0,0,6.44,32.32Zm3.88,3.88a1.78,1.78,0,0,1-2.46,0,1.74,1.74,0,1,1,2.46-2.46,1.74,1.74,0,0,1,0,2.46Z"/>
        <path style={{fill: color}} d="M32.27,32.32A3.72,3.72,0,0,0,31.17,35a3.76,3.76,0,0,0,3.74,3.74,3.72,3.72,0,0,0,2.65-1.1,3.74,3.74,0,0,0-5.29-5.29Zm3.87,3.88a1.78,1.78,0,0,1-2.46,0,1.74,1.74,0,1,1,2.46,0Z"/>
        <path style={{fill: color}} d="M6.44,6.5a3.75,3.75,0,0,0,2.65,6.39A3.74,3.74,0,0,0,11.73,6.5,3.74,3.74,0,0,0,6.44,6.5Zm3.88,3.88a1.74,1.74,0,1,1,0-2.46,1.74,1.74,0,0,1,0,2.46Z"/>
        <path style={{fill: color}} d="M28.49,20.51a1.92,1.92,0,0,0-1.85-.62,2,2,0,0,0-.62-.52,2.37,2.37,0,0,0-1.29-.24,2,2,0,0,0-.86-.67,2.26,2.26,0,0,0-.43-.11V13.21a2.33,2.33,0,0,0-4.65,0V19a4.29,4.29,0,0,0-1-.56,2.27,2.27,0,0,0-2,0,2.35,2.35,0,0,0-1.11,1.76c-.23,1.31.65,2.91,1.36,4.2.18.33.34.62.45.85s.26.65.42,1.09a10.79,10.79,0,0,0,1.94,3.81,4.36,4.36,0,0,1,.78,2.13,1,1,0,0,0,1,.88h5.87a1,1,0,0,0,1-.79,15.41,15.41,0,0,1,1.21-2.85l.17-.33c.51-1,.4-4.4.33-5.89A4.2,4.2,0,0,0,28.49,20.51Zm-1.37,7.86-.16.32a21.33,21.33,0,0,0-1.15,2.54h-4.3a5.08,5.08,0,0,0-1.23-2.46,11.94,11.94,0,0,1-1.43-3.05c-.19-.5-.35-.94-.48-1.23s-.31-.6-.52-1c-.43-.8-1.25-2.28-1.14-2.89.05-.25.11-.36.09-.36a.7.7,0,0,1,.28.07,2.69,2.69,0,0,1,1.44,1.59c.16,1,.85,1.12,1.13,1.11,1-.06,1.07-1,1.14-2.93V13.21a.33.33,0,0,1,.65,0v6.32a1,1,0,0,0,.51.87,1,1,0,0,0,1,0,.89.89,0,0,0,.17-.08.49.49,0,0,1,.11.24,1,1,0,0,0,.53.61,1,1,0,0,0,.8,0,.84.84,0,0,1,.52-.05s.05.07.09.23a1,1,0,0,0,.62.73,1,1,0,0,0,.95-.13c.09-.06.24-.12.25-.14a2.51,2.51,0,0,1,.25,1.43v.15A23.87,23.87,0,0,1,27.12,28.37Z"/>
    </svg>
    );
}

export default IconChoiceOfTechnology2
