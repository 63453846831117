import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconSimpleImplementation: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M43.29,11.58A11.51,11.51,0,0,0,23.73,3.35a2.8,2.8,0,0,0-1.59-.5H20.29a2.88,2.88,0,0,0-2.87,2.87v2a16,16,0,0,0-4.58,1.9L11.42,8.24a2.89,2.89,0,0,0-4.07,0l-1.3,1.3a2.89,2.89,0,0,0,0,4.07L7.47,15a15.84,15.84,0,0,0-1.89,4.6h-2A2.88,2.88,0,0,0,.71,22.51v1.84a2.88,2.88,0,0,0,2.88,2.88h2a15.61,15.61,0,0,0,1.91,4.58L6.1,33.22a2.89,2.89,0,0,0,0,4.07L7.4,38.6a2.89,2.89,0,0,0,4.07,0l1.43-1.43A15.73,15.73,0,0,0,17.49,39v2a2.89,2.89,0,0,0,2.88,2.88h1.84a2.89,2.89,0,0,0,2.88-2.88V39a15.93,15.93,0,0,0,4.56-1.91l1.44,1.43a2.87,2.87,0,0,0,4.07,0l1.3-1.3a2.89,2.89,0,0,0,0-4.07L35,31.72a16,16,0,0,0,1.86-4.57h2.05a2.88,2.88,0,0,0,2.88-2.88V22.43a2.87,2.87,0,0,0-1.9-2.69A11.52,11.52,0,0,0,43.29,11.58ZM31.77,2.06a9.52,9.52,0,1,1-9.52,9.52A9.53,9.53,0,0,1,31.77,2.06Zm-1,21.33a9.52,9.52,0,1,1-10.28-9.48,11.54,11.54,0,0,0,10.27,9.14C30.76,23.16,30.77,23.28,30.77,23.39Zm8.15-1.84a.88.88,0,0,1,.88.88v1.84a.87.87,0,0,1-.88.88H35.23l-.15.82a13.91,13.91,0,0,1-2.18,5.32l-.46.69,2.61,2.6a.89.89,0,0,1,0,1.25l-1.31,1.3a.88.88,0,0,1-1.24,0l-2.6-2.6-.68.47a14,14,0,0,1-5.32,2.23l-.81.16v3.67a.88.88,0,0,1-.88.88H20.37a.89.89,0,0,1-.88-.88V37.4l-.82-.15a14,14,0,0,1-5.35-2.18l-.68-.47-2.58,2.58a.88.88,0,0,1-1.24,0l-1.3-1.3a.86.86,0,0,1,0-1.24l2.57-2.57-.48-.69A13.83,13.83,0,0,1,7.39,26l-.16-.81H3.59a.88.88,0,0,1-.88-.88V22.51a.87.87,0,0,1,.88-.88H7.22l.15-.82a13.92,13.92,0,0,1,2.2-5.35l.47-.69L7.46,12.2a.88.88,0,0,1,0-1.24l1.3-1.31a.89.89,0,0,1,1.25,0l2.57,2.58.69-.47A13.94,13.94,0,0,1,18.6,9.54l.82-.15V5.72a.87.87,0,0,1,.87-.87h1.85a.76.76,0,0,1,.26.06,11.46,11.46,0,0,0-2.15,6.67c0,.12,0,.23,0,.34a11.52,11.52,0,1,0,12.5,11.47c0-.11,0-.22,0-.34a11.49,11.49,0,0,0,4.85-1.55v.05Z"/>
        <path style={{fill: color}} d="M30.76,16.13l7-7a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L30.76,13.3l-2.84-2.83a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.41Z"/>
    </svg>
    );
}

export default IconSimpleImplementation
