import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';

const LayoutPage = ({ children, path, pageContext }) => {
    return (
        <div>
            <Header pageContext={pageContext} />
            <main>
                {children}
            </main>
            {(pageContext.content && pageContext.content.footer) && <Footer pageContext={pageContext} />}
        </div>
    );
}

export default LayoutPage;
