import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconCosmetics: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M39.29,18.51V15.23a.93.93,0,0,0-.23-.6V5.47a1,1,0,0,0-1-1H28.81a1,1,0,0,0-1,1v9.16a1,1,0,0,0-.22.6v3.42a1,1,0,0,0-.11.43.93.93,0,0,0,.05.24,1.68,1.68,0,0,0-.86-.24V15.16a2.83,2.83,0,0,0-5.65,0v2.09a6.06,6.06,0,0,0-1.27-2,14.85,14.85,0,0,0-1.66-1.39c-.87-.64-1.28-.95-1.39-2a1,1,0,0,0,.93-1V6.62a1,1,0,0,0-1-1H15.38v-4a1,1,0,0,0-1-1H9.94a1,1,0,0,0-1,.92H4.1a1,1,0,0,0,0,2H8.94V5.62H7.69a1,1,0,0,0-1,1v4.24a1,1,0,0,0,.93,1c-.11,1.05-.52,1.36-1.39,2a14.85,14.85,0,0,0-1.66,1.39c-2.23,2.23-2.06,6.44-2.05,6.58V37.51A7.74,7.74,0,0,0,4.6,43a1.39,1.39,0,0,0,1,.41H18.15l.09,0a2.12,2.12,0,0,0,1.36.59H28a2,2,0,0,0,1.45-.62,2.31,2.31,0,0,0,.18-.23,1,1,0,0,0,.26.08c.54,0,1.91.12,3.45.12,2.47,0,5.39-.17,6.13-.84a1.14,1.14,0,0,0,.38-.85V20.8A3.62,3.62,0,0,0,39.29,18.51Zm-6-.43H29.59V16.23h7.7v1.85Zm3.8-11.61v7.76H29.81V6.47ZM19.25,31h9.19v6.14H19.25Zm0-2c0-.87.33-2.05,2.28-2.77a1,1,0,0,0,.66-.94V24.24H25.5v1.09a1,1,0,0,0,.65.94c2,.72,2.26,1.9,2.29,2.77Zm7.19-8v1.17H21.25V21.07Zm-2.6-6.73a.83.83,0,0,1,.83.82v3.91H23V15.16A.82.82,0,0,1,23.84,14.34ZM10.94,2.63h2.44v3H10.94Zm-2.25,5h6.94V9.86H8.69ZM4.52,37.51V21.77c0-1,.14-3.78,1.47-5.11a11.67,11.67,0,0,1,1.43-1.2,4.37,4.37,0,0,0,2.2-3.6H14.7a4.37,4.37,0,0,0,2.2,3.6,11.67,11.67,0,0,1,1.43,1.2,5.44,5.44,0,0,1,1.31,3,1,1,0,0,1,0,.1,1.74,1.74,0,0,0-.43,1.15v1.56A1.79,1.79,0,0,0,20.19,24v.66a4.74,4.74,0,0,0-2.94,4.5V40.41a5.86,5.86,0,0,0,.07,1H5.85A5.83,5.83,0,0,1,4.52,37.51Zm23.54,4.43H19.68c-.14,0-.43-.45-.43-1.53V39.18h9.19v1.23C28.44,41.49,28.15,41.91,28.06,41.94ZM37.89,41a37.8,37.8,0,0,1-7.5.2,6.56,6.56,0,0,0,.05-.79V29.17a4.74,4.74,0,0,0-2.94-4.5V24a1.79,1.79,0,0,0,.94-1.57V20.88a1.81,1.81,0,0,0-.23-.86.88.88,0,0,0,.27.06h9.35a4.11,4.11,0,0,1,.06.72Z"/>
    </svg>
    );
}

export default IconCosmetics
