import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconProductOnTheMarket: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M32.43,10.05H29.16V7.18a7.16,7.16,0,0,0-14.32,0v2.87H11.57a2.84,2.84,0,0,0-2.84,2.58L6,40.84A2.87,2.87,0,0,0,8.8,44H35.2A2.87,2.87,0,0,0,38,40.84L35.27,12.63A2.84,2.84,0,0,0,32.43,10.05ZM16.84,7.18a5.16,5.16,0,0,1,10.32,0v2.87H16.84Zm19,34.51a.82.82,0,0,1-.63.29H8.8a.82.82,0,0,1-.63-.29A.86.86,0,0,1,8,41l2.77-28.22a.85.85,0,0,1,.85-.77H32.43a.85.85,0,0,1,.85.77L36.05,41A.86.86,0,0,1,35.83,41.69Z"/>
        <path style={{fill: color}} d="M28.16,13.91a1,1,0,0,0-1,1,5.16,5.16,0,1,1-10.32,0,1,1,0,0,0-2,0,7.16,7.16,0,1,0,14.32,0A1,1,0,0,0,28.16,13.91Z"/>
    </svg>
    );
}

export default IconProductOnTheMarket
