import clsx from 'clsx';
import React from 'react'
import Typography from '../../Typography/Typography';

interface SectionTitleProps {
    title: string;
    subtitle?: string;
    light?: true;
    description?: string;
    centered?: true;
}

const SectionTitle: React.FC<SectionTitleProps> = props => {
    const { title, subtitle, description } = props;

    return (
        <div className={clsx("section-title", props.centered && "section-title--centered")}>
            {subtitle && <Typography light={props.light} variant="subtitle1" className="text-color__brand-1">{subtitle}</Typography>}
            <Typography light={props.light} variant="h2" color="primary">{title}</Typography>
            {description && <Typography light={props.light} variant="body1" className="secondary">{description}</Typography>}
        </div>
    )
}

export default SectionTitle
