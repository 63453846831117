import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconBactericidal: React.FC<IconProps> = (props) => {
    const { color } = props;

    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M39.28,11.57a2.24,2.24,0,0,0-.89.19,5.08,5.08,0,0,0-.63.35,10.39,10.39,0,0,0-4.07,5.08A10.65,10.65,0,0,0,33,20.9c0,.18,0,.28,0,.29h0a2.42,2.42,0,0,0,4.83-.05v-.52a6.22,6.22,0,0,1,.53-2.7,4.41,4.41,0,0,1,1.37-1.45,6.62,6.62,0,0,1,1-.6l.11,0,.1-.09A2.38,2.38,0,0,0,41.7,14,2.41,2.41,0,0,0,39.28,11.57Zm.61,3a8.19,8.19,0,0,0-1,.62,6,6,0,0,0-1.91,2,7.81,7.81,0,0,0-.72,3.42c0,.25,0,.44,0,.53a.84.84,0,0,1-1.67,0v-.05h0V20.9a9.14,9.14,0,0,1,.61-3.16,8.74,8.74,0,0,1,3.47-4.32A2.26,2.26,0,0,1,39,13.2a.69.69,0,0,1,.26-.06.84.84,0,0,1,.84.83A.81.81,0,0,1,39.89,14.53Z"/>
        <path style={{fill: color}} d="M3.22,22.07a2.59,2.59,0,0,0,1-.22A2.3,2.3,0,0,0,5,21.3a3.8,3.8,0,0,0,.42-.58A10.32,10.32,0,0,0,7,15.21a10.37,10.37,0,0,0-1.09-4.72h0A2.41,2.41,0,0,0,3.71,9.14a2.42,2.42,0,0,0-2.42,2.4,2.26,2.26,0,0,0,.23,1v0l0,0h0l0,0,.09.19a6.37,6.37,0,0,1,.31.7,6,6,0,0,1,.43,2v.14a4.33,4.33,0,0,1-.63,1.89,6.29,6.29,0,0,1-.66,1l-.08.1L1,18.75a2.41,2.41,0,0,0,0,1.92A2.42,2.42,0,0,0,3.22,22.07Zm-.79-2.65a9.91,9.91,0,0,0,.68-1A6,6,0,0,0,4,15.76a1.48,1.48,0,0,0,0-.21A7.24,7.24,0,0,0,3.45,13a7.64,7.64,0,0,0-.5-1.1.89.89,0,0,1-.08-.35.82.82,0,0,1,.49-.75.74.74,0,0,1,.35-.08.84.84,0,0,1,.76.48v.05h0l0,.05.1.2c.08.19.2.47.31.82a9.48,9.48,0,0,1,.46,2.89A8.74,8.74,0,0,1,4,19.9a1.88,1.88,0,0,1-.25.35.6.6,0,0,1-.21.17.93.93,0,0,1-.35.08A.84.84,0,0,1,2.46,20a.71.71,0,0,1-.08-.34A.7.7,0,0,1,2.43,19.42Z"/>
        <path style={{fill: color}} d="M23.32,2.61a2.36,2.36,0,0,0-.7-1.69,2.09,2.09,0,0,0-.76-.5,3.82,3.82,0,0,0-.69-.19,10.59,10.59,0,0,0-7.81,1.38,9.85,9.85,0,0,0-2,1.64h0a2.39,2.39,0,0,0,0,3.36,2.44,2.44,0,0,0,1.72.71,2.4,2.4,0,0,0,1.7-.7l0,0,0,0h0l0-.05L15,6.36c.14-.14.34-.34.59-.55a5.63,5.63,0,0,1,1.83-1.09,3.49,3.49,0,0,1,1-.14,7,7,0,0,1,2.1.38l.12,0h.22a2.41,2.41,0,0,0,2.42-2.4Zm-1.83.59a.8.8,0,0,1-.54.23A8,8,0,0,0,18.46,3,5,5,0,0,0,17,3.22a7.39,7.39,0,0,0-2.39,1.39,12.4,12.4,0,0,0-.93.9.82.82,0,0,1-.58.24.83.83,0,0,1-.6-.25.82.82,0,0,1-.24-.58.79.79,0,0,1,.25-.59l0,0,0,0h0v0l.08-.08.32-.3A9.7,9.7,0,0,1,14.2,3,9,9,0,0,1,19,1.57a9.82,9.82,0,0,1,1.88.19,2.42,2.42,0,0,1,.42.12A.7.7,0,0,1,21.5,2a.84.84,0,0,1,.24.59A.83.83,0,0,1,21.49,3.2Z"/>
        <path style={{fill: color}} d="M14.1,25a6.94,6.94,0,0,1,.53,1h0l0,.11.07.1a2.09,2.09,0,0,0,1.62.83h0A2.1,2.1,0,0,0,18,26.3a2.59,2.59,0,0,0,.6-1.63h0v0a2.89,2.89,0,0,0-.44-1.44,9.24,9.24,0,0,0-4.39-4.15,8.18,8.18,0,0,0-3.22-.72h-.22a2,2,0,0,0-1.52.74,2.53,2.53,0,0,0-.6,1.63h0v0a2.56,2.56,0,0,0,.58,1.64,2.06,2.06,0,0,0,1.56.75h.39a4.45,4.45,0,0,1,2.24.53A3.79,3.79,0,0,1,14.1,25Zm-3.78-3.39a.46.46,0,0,1-.36-.19,1,1,0,0,1-.21-.63h0A1,1,0,0,1,10,20.1a.55.55,0,0,1,.37-.2h.17a6.58,6.58,0,0,1,2.57.58A7.72,7.72,0,0,1,16.74,24a1.46,1.46,0,0,1,.24.68h0a1,1,0,0,1-.22.63.5.5,0,0,1-.37.19h0a.48.48,0,0,1-.33-.18,8.15,8.15,0,0,0-.52-1,5.55,5.55,0,0,0-1.75-1.94,6,6,0,0,0-3.08-.77Z"/>
        <path style={{fill: color}} d="M30.71,17.88a4.8,4.8,0,0,0,.4-1.71h0A2.18,2.18,0,0,0,28.93,14a2.35,2.35,0,0,0-1.07.26,1.85,1.85,0,0,0-.82.84,11.93,11.93,0,0,1-1.95,2.78h0a2.18,2.18,0,0,0-.64,1.53,2.2,2.2,0,0,0,2.2,2.19,2.1,2.1,0,0,0,.87-.2,4,4,0,0,0,.58-.36l-.45-.64.45.64A7.18,7.18,0,0,0,30.71,17.88Zm-3.52,1.89a2.11,2.11,0,0,1-.34.21.71.71,0,0,1-.2.06.63.63,0,0,1-.62-.62.6.6,0,0,1,.18-.43h0a14.13,14.13,0,0,0,2.24-3.21.22.22,0,0,1,.13-.12.76.76,0,0,1,.33-.08.6.6,0,0,1,.6.6v0c0,.05,0,.13,0,.23a4.79,4.79,0,0,1-.25.85A5.65,5.65,0,0,1,27.19,19.77Z"/>
        <path style={{fill: color}} d="M7.57,25.86a2.23,2.23,0,0,0-1.07.26,1.76,1.76,0,0,0-.82.84,12.89,12.89,0,0,1-1.94,2.78h0a2.16,2.16,0,0,0-.64,1.53,2.2,2.2,0,0,0,2.2,2.18,2.07,2.07,0,0,0,.87-.2,3.83,3.83,0,0,0,.59-.35,7.29,7.29,0,0,0,2.61-3.18A4.88,4.88,0,0,0,9.75,28h0A2.17,2.17,0,0,0,7.57,25.86Zm.6,2.21a2,2,0,0,0,0,.22,5.1,5.1,0,0,1-.25.86,5.7,5.7,0,0,1-2.06,2.48l.46.64-.46-.64a3.22,3.22,0,0,1-.34.21.45.45,0,0,1-.2.05.62.62,0,0,1-.62-.61.66.66,0,0,1,.19-.44h0a13.87,13.87,0,0,0,2.24-3.21s0-.07.13-.12a.77.77,0,0,1,.33-.07.59.59,0,0,1,.6.59Z"/>
        <path style={{fill: color}} d="M42.27,5.6A1.72,1.72,0,1,0,44,7.32,1.73,1.73,0,0,0,42.27,5.6Z"/>
        <path style={{fill: color}} d="M19.43,10a1.72,1.72,0,1,0,1.73,1.72A1.73,1.73,0,0,0,19.43,10Z"/>
        <path style={{fill: color}} d="M25.17,28.48A1.72,1.72,0,1,0,26.9,30.2,1.72,1.72,0,0,0,25.17,28.48Z"/>
        <path style={{fill: color}} d="M3.81,26.05a1.73,1.73,0,1,0-1.73,1.72A1.72,1.72,0,0,0,3.81,26.05Z"/>
        <path style={{fill: color}} d="M28.39,40.56a1.72,1.72,0,1,0,1.74,1.72A1.72,1.72,0,0,0,28.39,40.56Z"/>
        <path style={{fill: color}} d="M43.43,22.68a1.73,1.73,0,1,0-1.73,1.73A1.72,1.72,0,0,0,43.43,22.68Z"/>
        <path style={{fill: color}} d="M31.83,8.67a5.45,5.45,0,0,1,1-.92l.34,1.48a.79.79,0,1,0,1.54-.36l-.42-1.81a4.57,4.57,0,0,1,.89-.12,2.84,2.84,0,0,1,.63.07h0l.25.1.31,1.53a.78.78,0,0,0,.92.62.79.79,0,0,0,.63-.92L37.66,7A2.34,2.34,0,0,0,38.84,6a2.41,2.41,0,0,0,.26-1.07,2.35,2.35,0,0,0-1.23-2h0a6.08,6.08,0,0,0-1.3-.49l.07-1.24a.79.79,0,0,0-.75-.83.79.79,0,0,0-.83.74L35,2.11l-.55,0c-.27,0-.56,0-.87,0L33,.61a.79.79,0,0,0-1.49.52L32,2.38c-.21.05-.43.09-.65.16-.41.11-.77.23-1.12.36l-.53-1.24a.8.8,0,0,0-1-.41.78.78,0,0,0-.41,1l.55,1.29a6,6,0,0,0-1.34,1,4,4,0,0,0-.45.54l-1.43-.73a.81.81,0,0,0-1.07.34.79.79,0,0,0,.34,1.06l1.54.78a3.07,3.07,0,0,0-.07.6,2.94,2.94,0,0,0,1.61,2.62,2.68,2.68,0,0,0,1.24.31h.13l.5,1.47a.79.79,0,0,0,1.5-.51l-.5-1.47A4.37,4.37,0,0,0,31.83,8.67Zm-1.22-1a2.93,2.93,0,0,1-.74.65,1.39,1.39,0,0,1-.71.22,1.24,1.24,0,0,1-.51-.13,1.39,1.39,0,0,1-.76-1.23,1.31,1.31,0,0,1,.16-.63l0-.09,0-.09h0l.06-.12a2.25,2.25,0,0,1,.42-.57,6.79,6.79,0,0,1,3.17-1.63,10.11,10.11,0,0,1,2.69-.39,5.55,5.55,0,0,1,2.6.52h.05a.81.81,0,0,1,.42.68.73.73,0,0,1-.09.35.76.76,0,0,1-.69.42.79.79,0,0,1-.35-.09l-.06,0-.07,0a4.08,4.08,0,0,0-1.11-.13A5.21,5.21,0,0,0,33,5.81,6.27,6.27,0,0,0,30.61,7.68Z"/>
        <path style={{fill: color}} d="M24.15,37.46a5.12,5.12,0,0,0-1-1l.59-1.1a.79.79,0,0,0-1.39-.74l-.52,1A11.24,11.24,0,0,0,20.58,35l.16-1.6a.78.78,0,0,0-.7-.86.79.79,0,0,0-.87.7L19,34.56l-.66-.13c-.42-.07-.8-.11-1.16-.14l0-1.34a.79.79,0,0,0-.76-.82.81.81,0,0,0-.82.76l-.05,1.4a5.82,5.82,0,0,0-2.1.56l-.19.11-1-1.27a.79.79,0,1,0-1.25,1L12.15,36a2.9,2.9,0,0,0,0,3.57,2.67,2.67,0,0,0,1,.81l.08,0L13.09,42a.79.79,0,0,0,1.57.18l.18-1.53a4.59,4.59,0,0,0,1.35-.41,5.46,5.46,0,0,1,1.26-.42l-.32,1.48a.79.79,0,0,0,.61.93.8.8,0,0,0,.94-.6l.39-1.82a3.38,3.38,0,0,1,.81.25,2.63,2.63,0,0,1,.62.37h0l.19.2-.38,1.52a.79.79,0,0,0,1.53.38l.33-1.3h.09a2.33,2.33,0,0,0,1.89-3.72Zm-1.42,2a.76.76,0,0,1-.47.16.8.8,0,0,1-.61-.3l0-.06-.05,0a4.1,4.1,0,0,0-.9-.57,5.49,5.49,0,0,0-2.35-.52,6.5,6.5,0,0,0-2.8.67,2.75,2.75,0,0,1-1.11.28,1.28,1.28,0,0,1-.55-.11,1.12,1.12,0,0,1-.43-.34,1.37,1.37,0,0,1-.28-.84,1.35,1.35,0,0,1,.54-1.09l.08-.07.05-.07.06,0a2,2,0,0,1,.38-.25,4.75,4.75,0,0,1,2.16-.42,10.43,10.43,0,0,1,1.7.16c3.43.58,4.65,2.25,4.74,2.35v0l0,0a.74.74,0,0,1,.16.46A.76.76,0,0,1,22.73,39.45Z"/>
        <path style={{fill: color}} d="M37.26,28.77l0,.06a5.09,5.09,0,0,0-.69,2.58,5.91,5.91,0,0,0,.71,2.74L39.9,36.8l1.24,0a.81.81,0,0,0,.77-.81.78.78,0,0,0-.81-.76l-1.57,0A4.24,4.24,0,0,0,39,34a5.31,5.31,0,0,1-.6-1.19l1.52.11A.78.78,0,1,0,40,31.33l-1.88-.14a3.58,3.58,0,0,1,.42-1.49h0a1.92,1.92,0,0,0,.18-.22l1.56.17a.79.79,0,0,0,.86-.7.77.77,0,0,0-.7-.86l-1.35-.15a2.24,2.24,0,0,0-.68-1.42,2.32,2.32,0,0,0-1.64-.67,2.36,2.36,0,0,0-1.64.67h0a5.19,5.19,0,0,0-.86,1.09l-1.18-.43a.78.78,0,1,0-.54,1.47l1,.38a11.34,11.34,0,0,0-.35,1.15l1.35,1.34a6.55,6.55,0,0,1,1.6-3.82l0,0,0,0a.74.74,0,0,1,.55-.23.78.78,0,0,1,.54.22.76.76,0,0,1,.23.54.75.75,0,0,1-.22.54Z"/>
        <path style={{fill: color}} d="M31.57,33.93a.79.79,0,0,0-.7.86.8.8,0,0,0,.87.7l1.4-.15A6.07,6.07,0,0,0,33.78,37a4.33,4.33,0,0,0,.34.5L33,38.65a.78.78,0,0,0,0,1.11.8.8,0,0,0,1.12,0l1.21-1.23a3.06,3.06,0,0,0,1.43.38,3.09,3.09,0,0,0,.68-.09l-5-5Z"/>
        <path style={{fill: color}} d="M3.36,1.12A1,1,0,0,0,1.94,2.54L42,42.64a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z"/>
    </svg>
    );
}

export default IconBactericidal
