import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconDevelopASolution: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M36.25,24.52c-2.72-.14-4.95,1-7.1,2.07a15.53,15.53,0,0,1-4.28,1.67,8.7,8.7,0,0,1-3.74-.08,1.72,1.72,0,0,0-1.48.1,1.25,1.25,0,0,0-.53.91h0A9.61,9.61,0,0,0,19,30.5a9.85,9.85,0,1,0,18.34-5L37,25A1,1,0,0,0,36.25,24.52ZM28.88,38.35A7.86,7.86,0,0,1,21,30.5a2.71,2.71,0,0,1,0-.29,10.86,10.86,0,0,0,4.15,0,17.35,17.35,0,0,0,4.86-1.86,13.24,13.24,0,0,1,5.6-1.87v0a7.85,7.85,0,0,1-6.78,11.81Z"/>
        <path style={{fill: color}} d="M35,18.45V4.94a2.39,2.39,0,0,0,1.85-2.33V2.37A2.39,2.39,0,0,0,34.48,0H23.29a2.4,2.4,0,0,0-2.4,2.39v.24a2.4,2.4,0,0,0,1.86,2.33V18.45a13.52,13.52,0,1,0,12.27,0ZM22.89,2.37a.4.4,0,0,1,.4-.39H34.48a.4.4,0,0,1,.39.39v.24a.4.4,0,0,1-.39.4H23.29a.4.4,0,0,1-.4-.4Zm6,39.65a11.52,11.52,0,0,1-4.72-22,1,1,0,0,0,.59-.91V5H33V19.08a1,1,0,0,0,.59.91,11.52,11.52,0,0,1-4.73,22Z"/>
        <path style={{fill: color}} d="M25.29,27a2.77,2.77,0,1,0-2.78-2.77A2.77,2.77,0,0,0,25.29,27Zm0-3.54a.77.77,0,1,1-.78.77A.78.78,0,0,1,25.29,23.43Z"/>
        <path style={{fill: color}} d="M29.51,14.51a2.77,2.77,0,1,0-2.78-2.77A2.77,2.77,0,0,0,29.51,14.51Zm0-3.54a.77.77,0,1,1-.78.77A.78.78,0,0,1,29.51,11Z"/>
        <path style={{fill: color}} d="M13.45,8H2.6a1,1,0,1,0,0,2H3V39a5.07,5.07,0,1,0,10.13,0V10h.36a1,1,0,0,0,0-2ZM11.09,39A3.07,3.07,0,1,1,5,39V10h6.13Z"/>
    </svg>
    );
}

export default IconDevelopASolution
