import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconNatural100: React.FC<IconProps> = (props) => {
    const { color } = props;

    return (
        <svg viewBox="0 0 50 50">
            <path style={{ fill: color }} d="M26.36,17a6.41,6.41,0,0,1,2.37,4.78,6.4,6.4,0,0,1-2.37,4.77,6,6,0,0,1,2.18.6c1.18.57,1.7,1.52,4.21,1.52a5,5,0,0,0,4.74-5.12c0-2.88-2.56-4.11-3.55-4.68A15.28,15.28,0,0,0,26.36,17" />
            <path style={{ fill: color }} d="M31.71,29.64a6,6,0,0,0,5.21-1.72,5.66,5.66,0,0,0,1.66-4.83,13.14,13.14,0,0,1,2.27,7.38c0,4.4-1.37,5.35-2.37,5.92a5.38,5.38,0,0,1-5.63-.33s1.23-2.7-1.14-6.42" />
            <path style={{ fill: color }} d="M32.52,37a5.93,5.93,0,0,0,6.39.45s-1.75,4-7.72,6.2a3.48,3.48,0,0,1-3-2.32s3.27-1.47,4.36-4.33" />
            <path style={{ fill: color }} d="M27.07,41.31a4.11,4.11,0,0,0,2.75,3.27,16.85,16.85,0,0,1-7.79,2,10.08,10.08,0,0,1-4.79-.88s2.87-2,8.32-1.77c0,0-6.37-.79-8.19-2.64,0,0,3.53-1.23,9.7,0" />
            <path style={{ fill: color }} d="M15.32,22.26a1.49,1.49,0,1,1,1.48-1.49,1.49,1.49,0,0,1-1.48,1.49M23.81,17l-14.66.26s.26,8.78,8.78,9.29h5.45s4.23-.67,4.23-4.65a4.79,4.79,0,0,0-3.8-4.9" />
            <path style={{ fill: color }} d="M11,18.5v0a5.2,5.2,0,0,1-.35-1.85,4.56,4.56,0,0,1,2.68-4.11,8.11,8.11,0,0,1,4-.9c3.36,0,7.24,1.27,10.57,1.28.32,0,.64,0,1,0,3.11-.27,5.24-2.38,5.26-4.69a4,4,0,0,0-1.86-3.25A10.49,10.49,0,0,0,26.58,3.4a.54.54,0,1,0,0,1.07,9.52,9.52,0,0,1,5.08,1.32A2.9,2.9,0,0,1,33,8.17c0,1.6-1.59,3.39-4.29,3.63-.28,0-.56,0-.86,0-3.07,0-7-1.28-10.57-1.29a9.15,9.15,0,0,0-4.55,1,5.66,5.66,0,0,0-3.23,5A6.3,6.3,0,0,0,10,19a.54.54,0,0,0,.71.26A.53.53,0,0,0,11,18.5Z" />
        </svg>
    );
}

export default IconNatural100
