import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconNaturalThickener: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M39.29,31.91c-.49-1.41-2.22-2.61-5.07-3.51-1-2.33-6.13-3-8.61-3.17a8.61,8.61,0,0,1-.86-3s-.27-5.26-.31-6.31a2.21,2.21,0,0,0-4.42,0s0,0,0,0v0c0,.6-.17,4.08-.3,6.23a8.63,8.63,0,0,1-.88,3.06c-2.43.18-7.35.82-8.53,3-3.14.92-5,2.17-5.54,3.66C.84,33.51,0,35.34,0,36.63,0,41.4,11.34,44,22,44s22-2.58,22-7.35C44.07,35.34,43.22,33.51,39.29,31.91ZM22,42C9.61,42,2,38.86,2,36.63,2,35.8,3.07,34.86,4.93,34q.81,1.56,3.69,2.7a1,1,0,0,0,.37.07,1,1,0,0,0,.36-1.93c-2-.8-2.75-1.62-2.75-2.09s1.19-1.6,3.74-2.43c.52.9,1.76,1.63,3.7,2.18a.9.9,0,0,0,.28,0,1,1,0,0,0,.27-2c-2-.57-2.52-1.18-2.55-1.3,0-.31,1.31-1.38,5.26-1.89a6.27,6.27,0,0,1-.73.64,1,1,0,0,0-.26,1.39,1,1,0,0,0,.82.44,1,1,0,0,0,.57-.18,10.46,10.46,0,0,0,4-7.36c0-.56.34-5.44.31-6.43h0a.21.21,0,0,1,.42,0c0,1.09.31,6.39.31,6.43a10.45,10.45,0,0,0,4,7.33A1,1,0,0,0,27.9,28a5.26,5.26,0,0,1-.75-.65c3.93.5,5.27,1.54,5.28,1.89-.15.61-3.51,2.21-10.2,2.21a1,1,0,0,0,0,2c5,0,10.37-1,11.81-3.06,2.66.92,3.41,1.88,3.41,2.33,0,1.62-6,4-15.42,4-1.46,0-2.9,0-4.29-.17a1,1,0,0,0-1.08.91,1,1,0,0,0,.91,1.08c1.45.12,2.95.18,4.46.18,7.68,0,15.44-1.61,17.09-4.71,1.86.84,3,1.79,3,2.62C42.07,38.86,34.45,42,22,42Z"/>
        <path style={{fill: color}} d="M22.13,10.19A5.09,5.09,0,1,0,17,5.1,5.1,5.1,0,0,0,22.13,10.19Zm0-8.18A3.09,3.09,0,1,1,19,5.1,3.1,3.1,0,0,1,22.13,2Z"/>
        <path style={{fill: color}} d="M20.32,5.6a.5.5,0,0,0,.5-.5,1.41,1.41,0,0,1,1.41-1.41.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5A2.41,2.41,0,0,0,19.82,5.1.5.5,0,0,0,20.32,5.6Z"/>
    </svg>
    );
}

export default IconNaturalThickener
