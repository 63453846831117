import TRANSITIONS from "./transitions";

const ANIMATIONS = {
    cta: {
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                ...TRANSITIONS.spring
            }
        },
        hidden: {
            opacity: 0,
            y: 100
        }
    },
    button: {
        visible: {

            opacity: 1,
            transition: {
                ...TRANSITIONS.tween,
                delay: 0.2,
            }
        },
        hidden: {
            opacity: 0,

        }
    }
}

export default ANIMATIONS;