import clsx from 'clsx';
import React from 'react'

interface Props {
    variant: "h1" | "h2" | "h3" | "h4" | "h5" | "subtitle1" | "subtitle2" | "caption" | "body1" | "body2" | "none";
    className?: string;
    title?: true;
    color?: "primary" | "secondary" | "brand-1";
    light?: boolean;
    onClick?: () => void;
}

const Typography: React.FC<Props> = (props) => {
    const { children, variant, className, title, color, light, onClick } = props;


    let classColor = null;
    switch (color) {
        case "secondary":
            classColor = light ? "text-color__secondary--light" : "text-color__secondary";
            break;
        case "primary":
        default:
            classColor = light ? "text-color__primary--light" : "text-color__primary";
            break;
    }

    switch (variant) {
        case "h1":
            return (<h1 onClick={onClick} className={clsx(classColor, className, title && "title")}>{children}</h1>);
        case "h2":
            return (<h2 onClick={onClick} className={clsx(classColor, className)}>{children}</h2>);
        case "h3":
            return (<h3 onClick={onClick} className={clsx(classColor, className)}>{children}</h3>);
        case "h4":
            return (<h4 onClick={onClick} className={clsx(classColor, className)}>{children}</h4>);
        case "h5":
            return (<h5 onClick={onClick} className={clsx(classColor, className)}>{children}</h5>);
        case "body1":
        case "body2":
        case "subtitle1":
        case "subtitle2":
            return (<p onClick={onClick} className={clsx(classColor, className, variant)}>{children}</p>);
        case "caption":
            return (<span onClick={onClick} className={clsx(classColor, className, variant)}>{children}</span>);
        default:
            return (<span onClick={onClick} className={clsx(classColor, className)}>{children}</span>);
    }
}

export default Typography;
