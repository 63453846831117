import { Link } from 'gatsby';
import React from 'react';
import LogoLight from '../../images/logo-chitovelum-white.svg';
import LogoDark from '../../images/logo-chitovelum.svg';
import Typography from '../Typography/Typography';

interface Props {
    dark?: boolean;
    caption?: string;
    to: string;
}

const Logo: React.FC<Props> = (props) => {
    const { dark, caption, to } = props;

    return (
        <Link to={to} className="site-logo-link">
            <img
                className="site-logo"
                width="150"
                alt="Chitovelum logo"
                src={dark ? LogoDark : LogoLight} />
            {caption && <Typography variant="caption" color="secondary">{caption}</Typography>}
        </Link>
    );
}

export default Logo;
