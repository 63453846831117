import React from "react";

export default function useOnScreen(ref, threshold = 0.1) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    if (isIntersecting) return;

    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting), {
      threshold
    }
    );
    observer.observe(ref.current);

    if (isIntersecting) observer.disconnect();

    return () => {
      observer.disconnect();
    }
  }, [isIntersecting]);

  return isIntersecting;
}