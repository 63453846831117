import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconBrushing: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M41.81.51h0L41.73.42A2.69,2.69,0,0,0,39.58,0c-1.89.33-4.08,2.25-6,5.27C31,9.37,27.13,14.48,24.32,16.41a18.76,18.76,0,0,1-7.8,2.77.21.21,0,0,0-.11,0H16.3a1,1,0,0,0-.59.33l0,0s0,0,0,0l-.06.08a33.31,33.31,0,0,1-14,13,1,1,0,0,0-.22,1.7l12,9.33A1,1,0,0,0,14,44a1.13,1.13,0,0,0,.36-.06c5.24-2,10.09-7.14,12.85-10.49l.08-.06s0-.06,0-.08c1.34-1.63,2.17-2.82,2.27-3s0-.08,0-.12a.8.8,0,0,0,.11-.25c0-.06,0-.11,0-.16a1.14,1.14,0,0,0,0-.26s0-.05,0-.07a18.51,18.51,0,0,1,.77-8.24C31.67,18,35.7,13.07,39.07,9.5c2.46-2.61,3.78-5.2,3.64-7.11A2.68,2.68,0,0,0,41.81.51ZM26.31,31.33l-10.54-8.2c.33-.48.66-1,1-1.48l10.65,8.29C27.12,30.3,26.75,30.78,26.31,31.33ZM14.14,41.83l-1.2-.93a25.19,25.19,0,0,0,5.65-4.16,1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0,22.19,22.19,0,0,1-6,4.19l-1.22-1a25.58,25.58,0,0,0,5.65-4.15A1,1,0,0,0,14.18,33a22.63,22.63,0,0,1-6,4.19L7,36.25a24.86,24.86,0,0,0,5.65-4.16,1,1,0,0,0-1.42-1.41,22,22,0,0,1-6,4.19l-1.33-1a35.36,35.36,0,0,0,10.71-9.09L25,32.87C22.43,35.91,18.39,40,14.14,41.83ZM37.61,8.12c-1.7,1.81-7.38,8-9,12.4a20.54,20.54,0,0,0-1.06,7l-8.65-6.73a20.51,20.51,0,0,0,6.54-2.75c3.06-2.1,7.08-7.45,9.82-11.76,1.88-3,3.63-4.2,4.65-4.38A.78.78,0,0,1,40.5,2a.76.76,0,0,1,.21.54C40.79,3.57,40,5.58,37.61,8.12Z"/>
    </svg>
    );
}

export default IconBrushing
