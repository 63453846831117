import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';
import { PageContext } from '../../@types/PageContext';
import isContextLang from '../../utils/isContextLang';
import SITE_CONTENT from '../../../content/landing.json';
import uuid from 'react-uuid';

interface SwitchLocaleProps {
    pageContext: PageContext;
}

const SwitchLocale: React.FC<SwitchLocaleProps> = (props) => {
    const { pageContext } = props;

    const saveUserLocale = (locale: string) => {
        if (typeof window === 'undefined') return;
        const storage = window.localStorage;
        storage.setItem('language', locale);
    };

    const langs = Object.keys(SITE_CONTENT);

    return (
        <div className="switch-locale">
            {langs.map(lang => {
                console.log(pageContext, SITE_CONTENT[lang].locale);
                return (
                    <Link
                        key={uuid()}
                        to={`/${SITE_CONTENT[lang].slug}`}
                        onClick={() => saveUserLocale(lang)}
                        className={clsx(isContextLang(pageContext.locale, SITE_CONTENT[lang].locale) && "active")}>
                        {lang.toUpperCase()}
                    </Link>
                )
            })}
        </div>
    );
}

export default SwitchLocale;
