import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconCO2: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M40.67,19c.88-2.72.82-6.23-1.8-8.65A9.21,9.21,0,0,0,31.19,8,11.28,11.28,0,0,0,27.44,4.3,12.45,12.45,0,0,0,17.33,3.18a11.64,11.64,0,0,0-5.44,3.87,6.35,6.35,0,0,0-3.74-.18,6.24,6.24,0,0,0-4.57,4.37,7,7,0,0,0,.35,4.88c-3.69,3.4-4,6.48-3.9,9.06a8.94,8.94,0,0,0,8.82,8.37,1,1,0,0,0,1-1,1,1,0,0,0-1-1A7,7,0,0,1,2,25.06c-.15-2.7.44-5.09,3.82-7.95A1,1,0,0,0,6,15.76a4.82,4.82,0,0,1-.5-4,4.28,4.28,0,0,1,3.06-3,3.63,3.63,0,0,1,3,.35,1,1,0,0,0,.8.16,1,1,0,0,0,.66-.48A9.5,9.5,0,0,1,18,5.07,10.43,10.43,0,0,1,26.42,6a8.92,8.92,0,0,1,3.31,3.51,1,1,0,0,0,1.2.58,7.24,7.24,0,0,1,6.58,1.68c2.64,2.44,1.48,6.06,1.05,7.12a1,1,0,0,0,.33,1.17s3.46,2.64,3.15,6c-.33,3.77-2.85,5.94-6.88,5.94a1,1,0,0,0,0,2c5,0,8.45-3,8.88-7.76C44.36,22.74,41.84,20,40.67,19Z"/>
        <path style={{fill: color}} d="M15.05,36.16l-.31.39V28.4a1,1,0,0,0-2,0v8.15l-.31-.39a1,1,0,0,0-1.57,1.24L13,40a1,1,0,0,0,1.56,0l2.1-2.64a1,1,0,0,0-1.57-1.24Z"/>
        <path style={{fill: color}} d="M22,27.4a1,1,0,0,0-1,1v8.15l-.31-.39a1,1,0,0,0-1.57,1.24L21.22,40a1,1,0,0,0,1.56,0l2.1-2.64a1,1,0,0,0-1.57-1.24l-.31.39V28.4A1,1,0,0,0,22,27.4Z"/>
        <path style={{fill: color}} d="M30.26,27.4a1,1,0,0,0-1,1v8.15L29,36.16a1,1,0,0,0-1.57,1.24L29.48,40A1,1,0,0,0,31,40l2.1-2.64a1,1,0,0,0-1.57-1.24l-.31.39V28.4A1,1,0,0,0,30.26,27.4Z"/>
    </svg>
    );
}

export default IconCO2
