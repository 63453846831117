import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconSpray: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M24,3.35a11.74,11.74,0,0,0-3.41,9.19l-2.19,2.18a2.77,2.77,0,0,0,0,3.9l.77.77-1,1a2.19,2.19,0,0,0,0,3.07l2.4,2.4a2.14,2.14,0,0,0,1.53.63,2.16,2.16,0,0,0,1.54-.63l1-1,.77.77a2.83,2.83,0,0,0,3.9,0l2.19-2.19c.28,0,.56,0,.84,0A11.79,11.79,0,1,0,24,3.35Zm3.89,20.87a.78.78,0,0,1-1.07,0l-1.47-1.47a1,1,0,0,0-1.42,0l-1.73,1.73a.2.2,0,0,1-.23,0l-2.41-2.41a.2.2,0,0,1,0-.23l1.73-1.73a1,1,0,0,0,0-1.41l-1.47-1.48a.76.76,0,0,1,0-1.07l1.2-1.2A11.74,11.74,0,0,0,29.05,23Zm11.37-5.6a9.79,9.79,0,1,1,0-13.85A9.8,9.8,0,0,1,39.23,18.62Z"/>
        <path style={{fill: color}} d="M30,9.36A3.3,3.3,0,1,0,34.64,14h0a3.3,3.3,0,0,0,0-4.67A3.38,3.38,0,0,0,30,9.36Zm3.26,3.25a1.32,1.32,0,0,1-1.84,0,1.27,1.27,0,0,1-.39-.92,1.31,1.31,0,0,1,1.31-1.3,1.34,1.34,0,0,1,.92.38A1.32,1.32,0,0,1,33.23,12.61Z"/>
        <path style={{fill: color}} d="M17.09,26.91a1,1,0,0,0-1.41,0l-2.34,2.33a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l2.33-2.34A1,1,0,0,0,17.09,26.91Z"/>
        <path style={{fill: color}} d="M9.41,33.18,7.07,35.51a1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l2.33-2.34a1,1,0,0,0-1.41-1.41Z"/>
        <path style={{fill: color}} d="M13.83,23.9a1,1,0,0,0-1.16-.8l-3.25.62a1,1,0,0,0,.19,2,.65.65,0,0,0,.19,0L13,25.07A1,1,0,0,0,13.83,23.9Z"/>
        <path style={{fill: color}} d="M4,24.76l-3.25.61a1,1,0,0,0,.19,2l.19,0,3.24-.62a1,1,0,1,0-.37-2Z"/>
        <path style={{fill: color}} d="M20.1,30.17a1,1,0,0,0-1.17.79l-.61,3.24a1,1,0,0,0,.79,1.17.65.65,0,0,0,.19,0,1,1,0,0,0,1-.81l.62-3.25A1,1,0,0,0,20.1,30.17Z"/>
        <path style={{fill: color}} d="M18.45,38.87a1,1,0,0,0-1.17.8l-.62,3.24a1,1,0,0,0,.8,1.17l.19,0a1,1,0,0,0,1-.81L19.24,40A1,1,0,0,0,18.45,38.87Z"/>
    </svg>
    );
}

export default IconSpray
