import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconChitosan: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M22.09,43.94a135.14,135.14,0,0,0,17.16-1c3.17-.46,4.25-2.19,4.61-3.17a4.56,4.56,0,0,0-.85-4.5L25.93,16.17a5.16,5.16,0,0,0-7.67,0L1.18,35.25A5.17,5.17,0,0,0,0,40C.75,42.35,4.77,43.94,22.09,43.94ZM2.67,36.58,19.75,17.5a3.16,3.16,0,0,1,4.69,0L41.52,36.58A2.61,2.61,0,0,1,42,39.06c-.36,1-1.4,1.65-3,1.88a133.37,133.37,0,0,1-16.89,1c-16.86,0-19.86-1.6-20.13-2.5A3.14,3.14,0,0,1,2.67,36.58Z"/>
        <circle style={{fill: color}} cx="22" cy="21.95" r="1.43"/>
        <circle style={{fill: color}} cx="17.68" cy="25.75" r="1.43"/>
        <path style={{fill: color}} d="M25.93,28.61a1.43,1.43,0,1,0-1.43-1.43A1.43,1.43,0,0,0,25.93,28.61Z"/>
        <circle style={{fill: color}} cx="22" cy="30.14" r="1.43"/>
        <path style={{fill: color}} d="M15.72,33a1.43,1.43,0,1,0-1.43-1.43A1.43,1.43,0,0,0,15.72,33Z"/>
        <path style={{fill: color}} d="M26.5,33a1.43,1.43,0,1,0,1.43-1.43A1.43,1.43,0,0,0,26.5,33Z"/>
        <path style={{fill: color}} d="M17.68,35a1.43,1.43,0,1,0,1.43-1.43A1.43,1.43,0,0,0,17.68,35Z"/>
        <path style={{fill: color}} d="M9.43,35A1.43,1.43,0,1,0,8,33.59,1.43,1.43,0,0,0,9.43,35Z"/>
        <circle style={{fill: color}} cx="31.63" cy="30.14" r="1.43"/>
        <circle style={{fill: color}} cx="35.31" cy="35.65" r="1.43"/>
        <path style={{fill: color}} d="M26.5,36.45a1.43,1.43,0,1,0,1.43,1.43A1.43,1.43,0,0,0,26.5,36.45Z"/>
        <path style={{fill: color}} d="M15.72,39.94a1.43,1.43,0,1,0-1.43-1.43A1.43,1.43,0,0,0,15.72,39.94Z"/>
        <path style={{fill: color}} d="M27.58,12.24a1.43,1.43,0,1,0-1.43-1.43A1.43,1.43,0,0,0,27.58,12.24Z"/>
        <path style={{fill: color}} d="M19.92,9a1.43,1.43,0,1,0,1.66-1.15A1.44,1.44,0,0,0,19.92,9Z"/>
        <path style={{fill: color}} d="M14.29,15.28a1.43,1.43,0,1,0-1.43-1.43A1.43,1.43,0,0,0,14.29,15.28Z"/>
        <path style={{fill: color}} d="M19.11,5.94a1.43,1.43,0,1,0-1.43-1.43A1.43,1.43,0,0,0,19.11,5.94Z"/>
        <path style={{fill: color}} d="M24.5,4a1.44,1.44,0,1,0-1.43-1.44A1.44,1.44,0,0,0,24.5,4Z"/>
    </svg>
    );
}

export default IconChitosan
