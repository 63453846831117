import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconForAllSkinTypes: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M2.56,27a7.12,7.12,0,0,0,1.23.72c.4.2,1.33.67,1.4,1L5,29.29c-.41,1.06-.84,2.15-.4,3.11a2,2,0,0,0,1.26,1.06,7.48,7.48,0,0,0,.81.21,2.18,2.18,0,0,0,0,3.3c.9.91.71,1.44.45,1.87a3.28,3.28,0,0,0-.21,2.94,3.65,3.65,0,0,0,2.76,2,10.11,10.11,0,0,0,2,.18,30,30,0,0,0,5.47-.65,1,1,0,0,0-.44-2c-1.2.27-4.75.88-6.58.47A1.81,1.81,0,0,1,8.71,41a1.3,1.3,0,0,1,.08-1.12c.61-1,1-2.59-.75-4.31a.94.94,0,0,1-.17-.21,3.56,3.56,0,0,1,.62-.76l.32-.33a1.41,1.41,0,0,0,.44-1.47c-.25-.74-1-.87-1.67-1a7.59,7.59,0,0,1-1.14-.25l-.08,0A5.69,5.69,0,0,1,6.81,30c.09-.22.18-.44.26-.66.69-1.89-1.31-2.9-2.38-3.44a8.16,8.16,0,0,1-.9-.5l-.08-.07a22.38,22.38,0,0,1,2.61-3.51c2.58-3.07,2.3-6.35,2.07-9.24a22.87,22.87,0,0,1-.13-2.54c.07-2.55,2.67-7,3.67-8.5A1,1,0,1,0,10.26.45c-.15.24-3.91,5.9-4,9.56,0,.9.06,1.84.13,2.75.22,2.63.44,5.36-1.6,7.8-2.49,3-3.2,4-3.09,5.05A2,2,0,0,0,2.56,27Z"/>
        <path style={{fill: color}} d="M14.78,27a7.52,7.52,0,0,0,1.23.72c.4.2,1.34.67,1.4,1s-.15.4-.23.61c-.42,1.06-.85,2.15-.4,3.11A2,2,0,0,0,18,33.46a8.13,8.13,0,0,0,.81.21,2.19,2.19,0,0,0,0,3.3c.91.91.71,1.44.45,1.87a3.28,3.28,0,0,0-.2,2.94,3.61,3.61,0,0,0,2.76,2,10,10,0,0,0,2,.18,29.81,29.81,0,0,0,5.46-.65,1,1,0,0,0-.43-2c-1.21.27-4.75.88-6.58.47A1.81,1.81,0,0,1,20.93,41,1.27,1.27,0,0,1,21,39.87c.61-1,1-2.59-.76-4.31a1.61,1.61,0,0,1-.17-.21,3.66,3.66,0,0,1,.63-.76l.31-.33a1.41,1.41,0,0,0,.44-1.47c-.25-.74-1-.87-1.67-1a7.22,7.22,0,0,1-1.13-.25l-.08,0A5,5,0,0,1,19,30l.25-.66c.69-1.89-1.3-2.9-2.38-3.44a7.08,7.08,0,0,1-.89-.5l-.08-.07a21.18,21.18,0,0,1,2.61-3.51c2.57-3.07,2.3-6.35,2.06-9.24-.07-.9-.14-1.75-.12-2.54.06-2.55,2.66-7,3.66-8.5A1,1,0,0,0,23.88.17a1,1,0,0,0-1.39.28c-.16.24-3.91,5.9-4,9.56a23.11,23.11,0,0,0,.13,2.75c.21,2.63.44,5.36-1.6,7.8-2.5,3-3.2,4-3.09,5.05A2,2,0,0,0,14.78,27Z"/>
        <path style={{fill: color}} d="M27,27a7.63,7.63,0,0,0,1.24.72c.4.2,1.33.67,1.39,1s-.15.4-.23.61c-.42,1.06-.85,2.15-.4,3.11a2,2,0,0,0,1.26,1.06,6.93,6.93,0,0,0,.81.21,2.19,2.19,0,0,0,0,3.3c.91.91.71,1.44.46,1.87a3.28,3.28,0,0,0-.21,2.94,3.61,3.61,0,0,0,2.76,2,10.11,10.11,0,0,0,2,.18,29.67,29.67,0,0,0,5.46-.65,1,1,0,0,0-.43-2c-1.21.27-4.75.88-6.58.47A1.81,1.81,0,0,1,33.16,41a1.3,1.3,0,0,1,.08-1.12c.61-1,1-2.59-.75-4.31a.94.94,0,0,1-.17-.21,3.27,3.27,0,0,1,.62-.76l.31-.33a1.44,1.44,0,0,0,.45-1.47c-.26-.74-1-.87-1.68-1a7.48,7.48,0,0,1-1.13-.25l-.08,0A5.33,5.33,0,0,1,31.26,30c.09-.22.17-.44.25-.66.69-1.89-1.3-2.9-2.37-3.44a7.32,7.32,0,0,1-.9-.5l-.08-.07a22.38,22.38,0,0,1,2.61-3.51c2.57-3.07,2.3-6.35,2.06-9.24-.07-.9-.14-1.75-.12-2.54.06-2.55,2.67-7,3.67-8.5A1,1,0,0,0,34.71.45c-.16.24-3.91,5.9-4,9.56,0,.9.06,1.84.13,2.75.22,2.63.44,5.36-1.6,7.8-2.49,3-3.2,4-3.09,5.05A2,2,0,0,0,27,27Z"/>
    </svg>
    );
}

export default IconForAllSkinTypes
