import React from 'react'
import { IconProps } from '../Icon/Icon';

const IconNoPreservatives: React.FC<IconProps> = (props) => {
    const { color } = props;
    
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
        <path style={{fill: color}} d="M17.64,16.36l-5.45,5.46a1.58,1.58,0,0,0-.33.48L8.77,29.46a1.57,1.57,0,0,0,1.43,2.19,1.77,1.77,0,0,0,.63-.13L18,28.42a1.43,1.43,0,0,0,.49-.32l5.45-5.46L38.38,37.1a1,1,0,1,0,1.41-1.42L25.34,21.23,29,17.54l.62.62a2.54,2.54,0,0,0,3.6,0l.1-.1a2.54,2.54,0,0,0,0-3.6L33,14.12l5.85-5.85a4.79,4.79,0,0,0,1.41-3.42A4.84,4.84,0,0,0,32,1.43L26.16,7.27l-.33-.33a2.62,2.62,0,0,0-3.61,0l-.1.1a2.57,2.57,0,0,0,0,3.61l.62.61L19.06,15,5.43,1.33A1,1,0,0,0,4,2.74Zm3.82,5.92-1.11-1.11a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l1.1,1.1-2.93,2.93L11,29.25l2.62-6.08,5.4-5.39,3.45,3.45Zm12-19.44a2.92,2.92,0,0,1,4,0,2.87,2.87,0,0,1,0,4L31.6,12.71l-4-4ZM23.54,8.46l.1-.11A.57.57,0,0,1,24,8.2a.58.58,0,0,1,.39.15l7.51,7.52a.54.54,0,0,1,.16.39.56.56,0,0,1-.16.39l-.1.1a.54.54,0,0,1-.39.16.56.56,0,0,1-.39-.16L23.54,9.23a.54.54,0,0,1-.16-.39A.52.52,0,0,1,23.54,8.46Zm.62,4.22,3.45,3.45-1.21,1.2-1.1-1.1a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L25,18.75l-1.07,1.06-3.45-3.45Z"/><path style={{fill: color}} d="M14.34,39.84c0-1.85-2.9-6.19-4.14-6.19S6.05,38,6.05,39.84a4.15,4.15,0,1,0,8.29,0ZM10.2,42a2.15,2.15,0,0,1-2.15-2.15C8.05,39,9.39,37,10.2,36c.81,1,2.14,3,2.14,3.83A2.14,2.14,0,0,1,10.2,42Z"/>
    </svg>
    );
}

export default IconNoPreservatives
