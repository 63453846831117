import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react'
import isLinkLocal from '../../utils/isLinkLocal';
import Icon, { ICONS } from '../Icon/Icon';

interface Props extends ButtonColors {
    link: string | false;
    size?: "sm" | "md" | "lg";
    disabled?: boolean;
    fullwidth?: true;
    fullheight?: true;
    disableRoundedCorners?: true;
    children?: any;
    className?: string;
    onClick?: () => void;
    type?: "button" | "reset" | "submit";
    node_locale?: string;
    iconName?: ICONS;
}

export interface ButtonColors {
    color?: "brand-1" | "brand-2" | "brand-3" | "brand-4" | "brand-5" | "ghost";
}

const Button = React.forwardRef<any, Props>((props, ref) => {
    const { link, type, size, children, disabled, color, fullwidth, fullheight, disableRoundedCorners, className, onClick } = props;
    const isLocal = link && isLinkLocal(link);

    const classNames = clsx(
        "btn",
        className,
        color && `btn--${color}`,
        size ? `btn--${size}` : `btn--md`,
        disabled && "btn--disabled",
        fullwidth && "btn--fullwidth",
        fullheight && "btn--fullheight",
        disableRoundedCorners && "btn--disabledRoundedCorners"
    );

    if (!link) return <button type={type} onClick={onClick} ref={ref} className={classNames}>{props.iconName && <Icon iconName={props.iconName} />}<span>{children}</span></button>;
    if (isLocal) return <Link ref={ref} to={link} className={classNames}>{props.iconName && <Icon iconName={props.iconName} />}<span>{children}</span></Link>;
    if (!isLocal) return <a rel="noopener" target="_blank" ref={ref} href={link} className={classNames}>{props.iconName && <Icon iconName={props.iconName} />}<span>{children}</span></a>;
});

export default Button;
