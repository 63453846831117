import React from 'react'
import uuid from 'react-uuid';
import { PageContext, Person } from '../../@types/PageContext';
import Button from '../Button/Button';
import Container from '../Container/Container';
import SectionTitle from '../LANDING/SectionTitle/SectionTitle';
import SwitchLocale from '../SwitchLocale/SwitchLocale';
import Typography from '../Typography/Typography';

export interface FooterProps {
    pageContext: PageContext;
}

interface MenuItem {
    title: string;
    node_locale: string;
    slug: string;
    primaryMenu?: boolean;
    hideInMenus?: boolean;
}

const Footer: React.FC<FooterProps> = (props) => {
    const { pageContext } = props;
    const { title, description } = pageContext.content.footer;
    const { buttonName, buttonURL } = pageContext.content.contact;

    return (
        <footer className="site-footer">
            <Container maxWidth="xl" wrapperClassName="display--flex flex--centered flex--column">
                <SectionTitle light title={title} description={description} centered />
                <ContactInfo people={pageContext.content.contact.people} />
                <Button link={buttonURL} color="brand-5">{buttonName}</Button>
            </Container>
            <Container maxWidth="xl">
                <div className="display--flex flex--centered" style={{ marginBottom: "-5rem", marginTop: "5rem" }}>
                    <SwitchLocale pageContext={pageContext} />
                </div>
                <FooterInfo items={pageContext.content.footerInfo} />
            </Container>
        </footer>
    );
}

export default Footer;

interface ContactInfoProps {
    people: Person[];
}

const ContactInfo: React.FC<ContactInfoProps> = props => {
    const { people } = props;
    return (
        <div className="contact-info text-color__primary--light">
            {people.map(person => <ContactPerson key={uuid()} {...person} />)}
        </div>
    );
}

const ContactPerson: React.FC<Person> = props => {
    const { name, position, phone, mail } = props;
    return (
        <div className="contact-person">
            <Typography light variant="subtitle2">{name}</Typography>
            <Typography light variant="caption" color="secondary">{position}</Typography>
            <Typography light variant="body2" color="primary">{phone}</Typography>
            <Typography light variant="body2" color="primary">{mail}</Typography>
        </div>
    );
}

interface FooterInfo {
    items: string[];
}

const FooterInfo: React.FC<FooterInfo> = props => {
    return (
        <div className="footer-info">
            {props.items.map(item => <Typography key={uuid()} light variant="caption" color="secondary">{item}</Typography>)}
        </div>
    );
}
